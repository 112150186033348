import * as React from "react";
import {
	Box,
	Button,
	Container,
	Skeleton,
	CircularProgress,
} from '@mui/material';
import {
	Link,
	useNavigate,
} from "react-router-dom";
import View from '../../components/ui/View';
import HeaderPrimary from '../../components/layout/HeaderPrimary';
import { useAuth } from '../../providers/AuthProvider';
import { useMessage } from '../../providers/MessageProvider';

import SystemService from '../../services/SystemService';
import Loading from '../../components/ui/Loading';
import _ from 'lodash';
import WhiteBox from '../../components/layout/WhiteBox';

function LevelItem({exp, rate, level}) {
	const { user: currentUser } = useAuth();

	const levelIconColor = React.useMemo(() => {
		if (level==1 || level==2 || level==3) {
			return 'linear-gradient(to bottom, #6d5545, #2D2621)';
		} else if(level==4 || level==5 || level==6) {
			return 'linear-gradient(to bottom, #495163, #20232b)';
		} else {
			return 'linear-gradient(to bottom, #66614d, #2e2d25)';
		}
	}, [level]);
	const icon = React.useMemo(() => {
		const imageMapping = [
			'/image/level1.png',
			'/image/level2.png',
			'/image/level3.png',
			'/image/level4.png',
			'/image/level5.png',
			'/image/level6.png',
			'/image/level7.png',
			'/image/level8.png',
			'/image/level9.png',
		];
		return imageMapping[level-1];
	}, [level]);

	return (
		<WhiteBox
			sx={{
				display:"flex",
				height: 120,
				mt: 3,
				borderRadius: 4,
				borderColor: 'black',
				borderStyle: 'solid',
				borderWidth: currentUser?.level==parseInt(level) ? 2 : 0,
				overflow: 'hidden',
				opacity: currentUser?.level>parseInt(level) ? '0.4 !important' : 1,
			}}
		>
			<Box sx={{flex: 1,}}>
				<Box sx={{fontWeight:'bold', fontSize:26, lineHeight: '34px', marginTop:2, paddingLeft: 2}}>Lv {level}</Box>
				<View sx={{flexDirection:'row'}}>
					<Box sx={{flex:1, paddingLeft:2,}}>
						<Box sx={{marginTop:2,}}>{exp}</Box>
						<Box sx={{color:'black5', fontSize:12}}>Exp</Box>
					</Box>
					<Box sx={{flex:1, paddingLeft:2,}}>
						<Box sx={{marginTop:2,}}>+{rate}%</Box>
						<Box sx={{color:'black5', fontSize:12}}>Bonus</Box>
					</Box>
				</View>
			</Box>
			<Box 
        sx={{
        	height: 120,
        	width: 75,
        	display:'flex',
        	alignItems: 'center',
        	justifyContent: 'center',
        	backgroundImage:levelIconColor,
        }}
      >
      	<Box component="img" src={icon} sx={{width: 60, height: 26}} />
      </Box>
		</WhiteBox>
	);
}

function LevelPage(props) {
	const [levels, setLevels] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const message = useMessage();

	React.useEffect(() => {
		loadConfigs();
	}, [])

	const loadConfigs = async() => {
		try {
			setLoading(true);
			const response = await SystemService.getAppConfigs();
			setLevels(response);
		} catch(e) {
			message.error(e?.message)
		}
		setLoading(false);
	}

	const finalLevels = React.useMemo(() => {
		if (levels.length>0) {
			const groupData = _.groupBy(levels, 'key');
			const result = [];
			groupData.LEVEL.forEach((i, index) => {
				result.push({
					id: i.id,
					exp: i.value,
					rate: (parseFloat(groupData.LEVEL_REWARD_RATE[index].value)*100).toFixed(1),
					level: i.name,
				});
			});
			return result;
		}
		return [];
	}, [levels]);

	return (
		<Container >
			<HeaderPrimary label="Level" type="dark"/>
			{loading && <Loading />}
			{!loading && finalLevels.length>0 && (
				<Box sx={{pb:3}}>
					{finalLevels.map(i => (
						<LevelItem
							key={`level_item_${i.id}`}
							exp={i.exp}
							rate={i.rate}
							level={i.level}
						/>
					))}
				</Box>
			)}
		</Container>
	);
}

export default LevelPage