import * as React from "react";
import {
	Outlet,
} from "react-router-dom";
import {
	CssBaseline,
	Container,
	Box,
	Button,
	Slide,
	Alert,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2';
import Snackbar from '@mui/material/Snackbar'
import GlobalMessage from '../providers/MessageProvider';
import Copyright from '../components/layout/Copyright';
import CheckIcon from '@mui/icons-material/Check';

function MessageTransitionUp(props) {
  return <Slide {...props} direction="up" />;
}

export default function GlobalSnackbar(props) {
	let message = GlobalMessage.useMessage()

	return (
		<>
			<Snackbar
				open={message.messageOpen}
				message={message.messageContent}
				TransitionComponent={MessageTransitionUp}
				anchorOrigin={
					{vertical: 'bottom', horizontal: 'center'}
				}
			/>
			<Snackbar open={message.successOpen} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}>
        <Alert severity="success" variant="filled" icon={<CheckIcon fontSize="inherit" />} sx={{ color: 'white', fontWeight: 'bold' }}>
          {message.successContent}
        </Alert>
      </Snackbar>
		</>
	)
}