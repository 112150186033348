import * as React from "react";
import {
	Box,
} from '@mui/material';
import {
	useNavigate,
} from "react-router-dom";
import View from '../ui/View';
import TransactionService from '../../services/TransactionService';
import { currencyFormat, getCurrencySymbolByCurrency } from '../../services/UtilService';
import WhiteBox from '../layout/WhiteBox';
import SuccessResult from './SuccessResult';
import OrderDataColumn from './OrderDataColumn';

function DetailReward(props) {
	const {
		data,
	} = props;

	return (
		<>
			<WhiteBox sx={{my: 4}}>
				<SuccessResult />
				<OrderDataColumn title="Reward amount" value={`${getCurrencySymbolByCurrency(data.amount_currency)}${currencyFormat(data.amount)}`} />
				{data.object?.username_thumb && <OrderDataColumn title="Referee" value={`${data.object?.username_thumb}`} />}
				{data.remark && <OrderDataColumn title="Remark" value={`${data.remark}`} />}
				<OrderDataColumn title="Date" value={data.created_at} />
				
			</WhiteBox>
		</>
	);
}

export default DetailReward;

