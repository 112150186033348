import * as React from "react";
import {
  Box,
  TextField,
  IconButton,
  InputAdornment,
} from '@mui/material';
import {
  useNavigate,
} from "react-router-dom";
import View from '../ui/View';
import WhiteBox from '../layout/WhiteBox';
import SupportService from '../../services/SupportService';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { useMessage } from '../../providers/MessageProvider';
import LoadingButton from '@mui/lab/LoadingButton';
import PhotoCameraBackOutlinedIcon from '@mui/icons-material/PhotoCameraBackOutlined';

function SupportMessageFixedInput(props) {
  const {
    onSubmit,
    data,
    type,
  } = props;

  const [message, setMessage] = React.useState('');
  const [submiting, setSubmiting] = React.useState(false);
  const mes = useMessage();
  const [image, setImage] = React.useState();
  const imageUploaderRef = React.useRef()

  const openUploadMethodSelection = () => {
    imageUploaderRef.current.click()
  }

  const fileChange = (e) => {
    if (e.target.files.length) {
      setImage(e.target.files[0]);  
    }
  }

  const submitSupport = async() => {
    if (!message || !message.trim()) {
      return false;
    }
    try {
      setSubmiting(true);
      const response = await SupportService.createSupportMessage(type, data.id, message, image);
      onSubmit();
      setMessage('');
      setImage();
    } catch(e) {
      mes.show(e?.message)
    }
    setSubmiting(false);
  }

  const submitDisabled = React.useMemo(() => {
    if (message && message.trim()) {
      return false;
    } else {
      return true;
    }
  }, [message, image])

  return (
    <Box
      sx={{
        position:'fixed',
        width:'100%',
        height: 65,
        left:0,
        bottom:0,
        px:1,
        display:'flex',
        gap:1,
      }}
    >
      <TextField
        variant="standard" 
        value={message}
        onChange={e => setMessage(e.target.value)}
        size="small"
        placeholder="Send message"
        sx={{
          border:'none',
          backgroundColor:'white',
          borderRadius:50,
          height: 50,
          pt:1.5,
          flex:1,
          pl: 2,
          pr: 1,
          boxShadow:'0 2px 1px rgba(32,35,43,0.05)',
          '& input': {
            fontSize:14,
            pt: 0.3,
          },
          '& .MuiInput-root:before': {
            display:'none'
          },
          '& .MuiInput-root:after': {
            display:'none'
          },
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {!image && (
                <IconButton onClick={openUploadMethodSelection}>
                  <PhotoCameraBackOutlinedIcon />
                </IconButton>
              )}
              {image && (
                <Box
                  component="img"
                  sx={{width:40, height:40, borderRadius:40}}
                  src={URL.createObjectURL(image)}
                  onClick={openUploadMethodSelection}
                />
              )}
            </InputAdornment>
          )
        }}
      />
      <LoadingButton
        sx={{
          height: 50,
          width: 50,
          borderRadius: 50,
          p: 0,
          minWidth: 'auto',
          color: 'white',
          backgroundColor: submitDisabled ? 'primary.light' : 'primary.main',
          boxShadow:'0 2px 1px rgba(32,35,43,0.05)',
          ':hover': {
            backgroundColor: 'primary.main',
          },
        }}
        onClick={submitSupport}
        loading={submiting}
      >
        <SendRoundedIcon />
      </LoadingButton>
      <Box 
        ref={imageUploaderRef}
        component="input" 
        sx={{display: 'none'}} 
        type="file" 
        onChange={fileChange} 
        accept=".gif,.jpg,.jpeg,.png" 
      />
    </Box>
  );
}

export default SupportMessageFixedInput;