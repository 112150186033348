import * as React from "react";
import {
	Box,
} from '@mui/material';
import View from '../ui/View';
import KeyboardDoubleArrowRightRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowRightRounded';

function FlowStepper(props) {
	const {
		currentStep,
		steps=[]
	} = props;

	const css = {
		layer: {
			px:4,
			justifyContent:'center',
		},
		stepItem: {
			textAlign:'center',
			py:3,
			img: {
				width: 35,
			},
			title: {
				fontSize:12
			}
		},
		flowIcon: {
			color:"black4",
			mx:{xs:1.4, md:2},
			mt:3.6,
		},
	}

	return (
		<View sx={css.layer}>
			{steps.map((i, index) => {
				return (
					<React.Fragment key={`steper_${index}`} >
						<Box sx={{...css.stepItem, opacity: currentStep!=(index+1) ? 0.4 : 1,}} >
							<Box
								component="img"
								src={i.icon}
								sx={{
									...css.stepItem.img,
									filter: currentStep!=(index+1) ? 'grayscale(100%)' : 'grayscale(0%)',
								}}
							/>
							<Box sx={{...css.stepItem.title, fontWeight: currentStep==(index+1) ? 'bold' : 'normal'}}>{i.name}</Box>
						</Box>
						{(index+1)!=steps.length && (
							<KeyboardDoubleArrowRightRoundedIcon sx={css.flowIcon} key={`steper_icon_${index}`}/>
						)}
					</React.Fragment>
				);
			})}
		</View>
	)
}
export default FlowStepper;

