import * as React from "react";
import {
	Box,
	Button,
	Container,
	TextField,
	FormHelperText,
	InputAdornment,
} from '@mui/material';
import {
	useNavigate,
} from "react-router-dom";
import View from '../../components/ui/View';
import HeaderPrimary from '../../components/layout/HeaderPrimary';
import { useAuth } from '../../providers/AuthProvider';
import { useMessage } from '../../providers/MessageProvider';
import WhiteBox from '../../components/layout/WhiteBox';
import GradientButton from '../../components/ui/GradientButton';
import UserService from '../../services/UserService';
import _ from 'lodash';
import VpnKeyRoundedIcon from '@mui/icons-material/VpnKeyRounded';
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';

function ChangeLoginPasswordPage(props) {
	const [oldPassword, setOldPassword] = React.useState('');
	const [newPassword, setNewPassword] = React.useState('');
	const [confirmNewPassword, setConfirmNewPassword] = React.useState('');
	const message = useMessage();
	const [submiting, setSubmiting] = React.useState(false);
	const [errors, setErrors] = React.useState({
		newPassword: null,
		confirmNewPassword: null,
	});
	const navigate = useNavigate();

	const resetError = (key) => {
		setErrors({
			...errors,
			[key]: null
		})
	}

	const savePassword = async() => {
		try {
			let errorBag = {};
			
			if (newPassword.trim().length<5 || newPassword.trim().length>30) {
				errorBag['newPassword'] = 'Password at least 5 characters';
			}
			if (confirmNewPassword!=newPassword) {
				errorBag['confirmNewPassword'] = 'Passwords do not match';
			}
			if (!_.isEmpty(errorBag)) {
				setErrors({
					...errors,
					...errorBag,
				})
				return;
			}
			setSubmiting(true);
			const response = await UserService.changePassword(oldPassword, newPassword);
			message.showSuccess('Password change complete');
			navigate(-1);
		} catch(e) {
			message.show(e?.message);
		}
		setSubmiting(false);
	}

	return (
		<Container >
			<HeaderPrimary label="Change Password" type='dark' />
			<Box sx={{mt:2}}>
				<TextField 
					onChange={t => setOldPassword(t.target.value.trim())}
					placeholder="Current password"
					sx={styles.input}
					type="password"
					fullWidth
					InputProps={{
			        	startAdornment: (
			            <InputAdornment position="start">
			              <VpnKeyRoundedIcon sx={{color:'black7'}} />
			            </InputAdornment>
			          ),
			        }}
				/>
				<TextField 
					onChange={t => {
						resetError('newPassword');
						setNewPassword(t.target.value.trim());
					}}
					InputProps={{
			        	startAdornment: (
			            <InputAdornment position="start">
			              <LockOpenRoundedIcon sx={{color:'black7'}} />
			            </InputAdornment>
			          ),
			        }}
					placeholder="New password"
					sx={styles.input}
					type="password"
					fullWidth
					error={errors.newPassword}
				/>
				{errors.newPassword && <FormHelperText error>{errors.newPassword}</FormHelperText>}
				<TextField 
					onChange={t => {
						resetError('confirmNewPassword');
						setConfirmNewPassword(t.target.value.trim());
					}}
					InputProps={{
			        	startAdornment: (
			            <InputAdornment position="start">
			              <LockOpenRoundedIcon sx={{color:'black7'}} />
			            </InputAdornment>
			          ),
			        }}
					placeholder="Confirm new password"
					sx={styles.input}
					type="password"
					fullWidth
					error={errors.confirmNewPassword}
				/>
				{errors.confirmNewPassword && <FormHelperText error>{errors.confirmNewPassword}</FormHelperText>}
				<GradientButton
					disabled={!oldPassword || !newPassword || !confirmNewPassword}
					loading={submiting}
					onClick={savePassword}
					fullWidth
					sx={{mt:2,}}
					size="large"
				>Submit</GradientButton>
			</Box>
		</Container>
	);
}

export default ChangeLoginPasswordPage;

const styles = {
	input: {
		mt: 2,
	}
};