import * as React from "react";
import {
	Box,
	Container,
	Typography,
	Modal,
	IconButton,
	TextField,
	InputAdornment,
	FormHelperText,
} from '@mui/material';
import {
	useNavigate,
	useSearchParams,
	useLocation,
} from "react-router-dom";
import { useAuth } from '../../providers/AuthProvider';
import { useMessage } from '../../providers/MessageProvider';
import {
	getCurrencySymbolByCurrency,
	currencyFormat,
} from '../../services/UtilService';
import View from '../../components/ui/View';
import WhiteBox from '../../components/layout/WhiteBox';
import CardService from '../../services/CardService';
import UserService from '../../services/UserService';
import CouponService from '../../services/CouponService';
import SelectGiftCardCategoryModal from '../../components/transaction/SelectGiftCardCategoryModal';
import HeaderPrimary from '../../components/layout/HeaderPrimary';
import _ from 'lodash';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import LocalAtmOutlinedIcon from '@mui/icons-material/LocalAtmOutlined';
import QrCode2OutlinedIcon from '@mui/icons-material/QrCode2Outlined';
import GradientButton from '../../components/ui/GradientButton';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import LoadingModal from '../../components/ui/LoadingModal';

function SubmitGiftCardPage(props) {
	let [searchParams, setSearchParams] = useSearchParams();
	const message = useMessage();
	const location = useLocation();
	const { user: currentUser, reloadUser } = useAuth();
	const [selectedCard, setSelectedCard] = React.useState();
	const [selectedCardCategoryId, setSelectedCardCategoryId] = React.useState(searchParams.get('cardCategoryId'));
	const [cardAmount, setCardAmount] = React.useState('');
	const [ecode, setEcode] = React.useState('');
	const [images, setImages] = React.useState([]);
	const [categoryVisible, setCategoryVisible] = React.useState(false);
	const [submiting, setSubmiting] = React.useState(false);
	const [availableCoupons, setAvailableCoupons] = React.useState([]);
	const [useCoupon, setUseCoupon] = React.useState();
	const inited = React.useRef(false);
	const [errors, setErrors] = React.useState({
		cardAmount: null,
	});
	const imageUploaderRef = React.useRef()
	const navigate = useNavigate();

	React.useEffect(() => {
		initData();
	}, []);

	React.useEffect(() => {
		if (location.state?.selectCoupon) {
			setUseCoupon(location.state?.selectCoupon);
		}
	}, [location]);

	const initData = () => {
		if (!inited.current) {
			loadCard();
			reloadUser();
			getCoupons();
		}
		inited.current = true;
	}

	const loadCard = async() => {
		try {
			const response = await CardService.getCardDetail(searchParams.get('cardId'));
			setSelectedCard(response)
		} catch(e) {
			message.show(e?.message);
		}
	}

	const getCoupons = async() => {
		try {
			const response = await UserService.getAllAvailableCoupons();
			setAvailableCoupons(response)
		} catch(e) {
		}
	}

	const handleCardCategorySelect = (e) => {
		setCategoryVisible(false);
		setImages([]);
		setEcode('');
		setSelectedCardCategoryId(e.id);
	}

	const selectedCateCategory = React.useMemo(() => {
		if (selectedCard && selectedCardCategoryId) {
			const result = selectedCard.active_categories.find(i => i.id==selectedCardCategoryId);
			return result;
		} else {
			return null;	
		}
	}, [selectedCard, selectedCardCategoryId])

	const submitCard = async() => {
		try {
			setSubmiting(true);
			const transaction = await CardService.sellCard(
				selectedCardCategoryId,
				cardAmount,
				parseFloat(cashValue+levelValue+couponValue),
				ecode,
				images,
				useCoupon?.id
			);
			navigate(`/success`, {
				state: {
					type: 'sell',
					transaction,	
				},
				replace: true,
			});
		} catch(e) {
			message.show(e.message)
		}
		setSubmiting(false);
	}

	const cashValue = React.useMemo(() => {
		if (selectedCateCategory && cardAmount>0) {
			if (currentUser.country=='GHANA') {
				return selectedCateCategory.current_cedi_rate.rate*cardAmount;
			} else {
				return selectedCateCategory.current_rate.rate*cardAmount;	
			}
		} 
		return 0;
	}, [selectedCateCategory, cardAmount]);

	const levelValue = React.useMemo(() => {
		if (cashValue>0 && currentUser) {
			return parseInt(cashValue * currentUser.bonus_rate)
		}
		return 0;
	}, [cashValue, currentUser]);

	const couponValue = React.useMemo(() => {
		if (cashValue>0 && useCoupon) {
			if (useCoupon.coupon.type==CouponService.TYPE_PERCENT) {
				return parseInt(cashValue * parseFloat(useCoupon.coupon.amount_reward) / 100);
			} else if (useCoupon.coupon.type==CouponService.TYPE_AMOUNT) {
				return parseFloat(currentUser.country=='GHANA' ? useCoupon.coupon.amount_cedi_reward : useCoupon.coupon.amount_reward);
			} else {
				return 0;
			}
		}
		return 0;
	}, [cashValue, useCoupon]);

	const formHasError = React.useMemo(() => {
		return _.values(errors).filter(i => !!i).length
	}, [errors]);

	const openUploadMethodSelection = () => {
		imageUploaderRef.current.click()
	}

	const removeImage = (index) => {
		const newImages = [...images];
		newImages.splice(index, 1);
		setImages(newImages);
	}

	const checkCardAmountAvailable = () => {
		if (cardAmount && cardAmount<selectedCateCategory?.min_amount) {
			setErrors({...errors, cardAmount: `Amount should be at least ${selectedCateCategory.min_amount}`})
		} else {
			setErrors({...errors, cardAmount: null})
		}
	}

	const toCouponSelectScreen = () => {
		navigate(`/coupons/choose?cardId=${searchParams.get('cardId')}&cardCategoryId=${searchParams.get('cardCategoryId')}`);
	}

	const fileChange = (e) => {
		if (e.target.files.length) {
			setImages([...images, e.target.files[0]]);	
		}
	}

	const submitBlocked = React.useMemo(() => {
		let result = false;
		if (cardAmount && !formHasError) {
			if (selectedCard.type==CardService.TYPE_GIFTCARD) {
				if (ecode || images.length>0) {
					result = true;
				}
			} else {
				result = true;
			}
		}
		return result;
	}, [cardAmount, ecode, images, formHasError, selectedCard]);

	return (
		<>
			<Container sx={{pb:'250px'} }>
				<HeaderPrimary label={selectedCard?.name} type='dark' />

				<View sx={{alignItems: 'center', flexDirection:'column'}}>
					{selectedCard && <Box component="img" src={selectedCard?.image} sx={styles.cardImage}/>}
					{!selectedCard && <View sx={{...styles.cardImage, backgroundColor: 'black4'}}/>}
				</View>

				<View sx={styles.categoryItem} onClick={() => setCategoryVisible(true)}>
					{selectedCateCategory && (
						<>
							<Box component="img" src={selectedCateCategory.country.image} sx={styles.categoryItem.image}  />
							<Box sx={styles.labelMedium} >{selectedCateCategory.display_name}</Box>
						</>
					)}
					{!selectedCateCategory && (
						<>
							<Box sx={styles.labelMedium} >Loading...</Box>
						</>
					)}
					<KeyboardArrowDownRoundedIcon sx={{width:30, height:30, colors:'black4'}} />
				</View>

				<View sx={styles.amountItem}>
					<View sx={styles.amountInputLayer}>
						<TextField 
							value={cardAmount}
							onChange={t => {
								setCardAmount(t.target.value.trim());
							}}
							type="number"
							inputProps={{ maxLength: 4 }}
							InputProps={{
			        	startAdornment: (
			            <InputAdornment position="start">
			              <LocalAtmOutlinedIcon sx={{color:'rgba(0,0,0,0.8)'}} />
			            </InputAdornment>
			          ),
			          sx: {
			          	borderRadius: '10px 0 0 10px',
			          	height:60,
			          }
			        }}
							placeholder="Balance"
							sx={styles.amountInput}
							error={!!errors.cardAmount}
							onBlur={checkCardAmountAvailable}
						/>
					</View>
					<View sx={styles.amountRateItem}>
							{getCurrencySymbolByCurrency(currentUser.currency)}
							{selectedCateCategory ? ( currentUser.country=='GHANA' ? selectedCateCategory.current_cedi_rate.rate : selectedCateCategory.current_rate.rate) : ' -'}
					</View>
				</View>
				{errors.cardAmount && <FormHelperText error>{errors.cardAmount}</FormHelperText>}

				{selectedCard?.type==CardService.TYPE_GIFTCARD && (
					<>
						<TextField
							fullWidth 
							onChange={t => setEcode(t.target.value)}
							InputProps={{
			        	startAdornment: (
			            <InputAdornment position="start">
			              <QrCode2OutlinedIcon sx={{color:'rgba(0,0,0,0.8)'}} />
			            </InputAdornment>
			          ),
			          sx: {
			          	height:60,
			          }
			        }}
							placeholder="Enter card data (Optional)"
							sx={styles.ecodeInput}
						/>

						<View sx={styles.uploadSection}>
							{images.map((i, index) => (
								<Box sx={styles.uploadItem} key={`images_${index}`}>
									<Box component="img" src={URL.createObjectURL(i)} sx={styles.uploadItem.image} />
									<View sx={styles.deleteIcon} onClick={() => removeImage(index)}>
										<ClearRoundedIcon sx={{fontSize:16, color:'white'}} />
									</View>
								</Box>	
							))}
							<Box onClick={openUploadMethodSelection} sx={styles.uploadButtonLayer}>
								<View sx={styles.uploadButton}>
									<AddPhotoAlternateOutlinedIcon sx={styles.uploadButton.icon} />
									<Box sx={styles.uploadButton.text}>Upload</Box>
								</View>
							</Box>
							<Box 
								ref={imageUploaderRef}
								component="input" 
								sx={{display: 'none'}} 
								type="file" 
								onChange={fileChange} 
								accept=".gif,.jpg,.jpeg,.png" 
							/>
						</View>
					</>
				)}

				{selectedCard?.tips && (
					<Box sx={styles.tipsSection}>
						<Box sx={{fontWeight:'bold', color:'black8', mb:1}}>TIPS</Box>
						{selectedCard?.tips}
					</Box>
				)}
			</Container>

			<View sx={styles.bottomSection}>
				<Box sx={styles.totalInfo}>
					{levelValue>0 && (
						<View sx={styles.valueItem}>
							<Box sx={{color: 'black5'}}>Level bonus</Box>
							<Box>{getCurrencySymbolByCurrency(currentUser.currency)}{currencyFormat(levelValue)}</Box>	
						</View>
					)}
					{availableCoupons.length>0 && (
						<View onClick={toCouponSelectScreen} sx={styles.valueItem}>
							<Box sx={{color: 'black5'}}>Coupon bonus</Box>
							{useCoupon && <Box >{getCurrencySymbolByCurrency(currentUser.currency)}{currencyFormat(couponValue)}</Box>}
							{!useCoupon && <Box sx={{backgroundColor: 'primary.main', px:1.5, py:0.5, color:'white',  borderRadius: 10, cursor:'pointer'}}>{availableCoupons.length} coupons ></Box>}
						</View>
					)}
					<View sx={{alignItems: 'center', mt: 1, flexDirection:'column'}}>
						<Box sx={{color: 'black5', fontSize:14,}}>Total value</Box>
						<Box sx={styles.totalCashValue}>{getCurrencySymbolByCurrency(currentUser.currency)} {currencyFormat(cashValue+levelValue+couponValue)}</Box>
					</View>
				</Box>
				<GradientButton 
					loading={submiting}
					disabled={!submitBlocked}
					onClick={submitCard}
					sx={styles.submitButton}
					endIcon={<ChevronRightRoundedIcon sx={{fontSize:'40px !important',}}/>}
				>
					Submit Trade
				</GradientButton>
			</View>

			<SelectGiftCardCategoryModal
				open={categoryVisible}
				onClose={() => setCategoryVisible(false)}
				onSelect={handleCardCategorySelect}
				card={selectedCard}
			/>
			<LoadingModal open={!!!selectedCateCategory} />
		</>
	)
}

export default SubmitGiftCardPage;

const styles = {
	labelMedium: {
		whiteSpace:'nowrap',
		textOverflow:'ellipsis',
		overflow:'hidden',
		fontSize:14,
		flex:1,
	},
	cardImage: {
		height: 55,
		objectFit:'cover',
		borderRadius: 2,
		my: 3,
	},
	categoryItem: {
		height: 60,
		backgroundColor: '#fff',
		alignItems: 'center',
		justifyContent: 'space-between',
		flexDirection: 'row',
		borderRadius: 3,
		mb: 3,
		px: 2,
		cursor:'pointer',
		image: {
			width: 16,
			height: 16,
			mr: 2,
		},
	},
	amountInputLayer: {
		flex: 1,
	},
	amountInput: {
		flex:1,
		height: 60,
	},
	amountItem: {
		flexDirection: 'row',
		alignItems: 'flex-start',
	},
	amountRateItem: {
		px: 5,
		backgroundColor: '#20232b',
		borderRadius: '0 10px 10px 0',
		height: 60,
		justifyContent: 'center',
		alignItems:'center',
		color: '#FFF',
		fontWeight:'bold',
	},
	ecodeInput: {
		mt:3,
	},
	uploadSection: {
		flexWrap: 'wrap',
		mt:3,
	},
	uploadItem: {
		position:'relative',
		width: 100,
		height: 100,
		mr: 2,
		mb: 2,
		image: {
			width: 100,
			height: 100,
			borderRadius: 3,
		},
	},
	deleteIcon: {
		position: 'absolute',
		right: 0,
		top: 0,
		backgroundColor: 'rgba(0,0,0,0.6)',
		width: 21,
		height: 21,
		borderRadius: '0 10px 0 10px',
		alignItems: 'center',
		justifyContent: 'center',
		zIndex:1,
	},
	uploadButtonLayer: {
		mr: 2,
		mb: 2,
		borderRadius: 2,
		backgroundColor: 'white',
	},
	uploadButton: {
		width: 100,
		height: 100,
		borderRadius: 2,
		cursor:'pointer',
		flexDirection:'column',
		alignItems: 'center',
		justifyContent: 'center',
		borderStyle: 'dashed',
		borderWidth: 1,
		borderColor: '#70778b',
		icon: {
			color: '#4f5564',
			fontSize:30,
		},
		text: {
			color: '#4f5564',
			mt: 1,
			fontSize:13
		},
	},
	bottomSection: {
		position:'fixed',
		bottom:0,
		left:0,
		width:'100%',
		zIndex:2,
		flexDirection:'column',
		backgroundColor: 'white',
	},
	totalInfo: {
		border:'10px 10px 0 0',
		alignItems: 'center',
		backgroundColor: 'white',
		pb: 1,
		boxShadow: '0 -4px 8px rgba(0,0,0,0.05)',
	},
	totalCashValue: {
		mt: 0.5,
		fontWeight:'bold',
		fontSize:{xs:26, md:30},
	},
	submitButton: {
		borderRadius: 0,
		cursor:'pointer',
		height: {xs:60, md:70},
		fontSize: {xs:18, md:20},
	},
	valueItem: {
		flexDirection: 'row',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'space-between',
		px:2,
		py:1,
		fontSize:13,
		borderBottom:'1px solid #f3f4f5',
	},
	tipsSection: {
		fontSize: 12,
		color: 'black6',
		py:2,
		px:1,
		whiteSpace:'pre-line',
		backgroundColor:'black2',
		borderRadius:2,
		mt:2,
	},
}