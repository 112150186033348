import Request from './Request';

export default {
	TYPE_ONETIME: 1,
	TYPE_DAILY: 2,
	REWARD_TYPE_EXP: 1,
	REWARD_TYPE_LOTTERY: 2,
	REWARD_TYPE_COUPON: 3,
	STATUS_AVAILABLE_CHECK: 1,
    STATUS_DONE: 2,
    STATUS_INACTIVE: 0,
	async getUserTasks() {
		return Request.get('/api/tasks');
	},
	async checkUserTask(userTaskId) {
		return Request.post(`/api/tasks/${userTaskId}/check`);
	}
}