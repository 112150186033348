import * as React from "react";
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet
} from "react-router-dom";
import UserService from '../services/UserService'

let AuthContext = React.createContext(null);

export const Provider = (props) => {
  let [user, setUser] = React.useState(UserService.getLocalUser());

  let signIn = async (user) => {
    setUser(user)
    UserService.setLocalUser(user)
    return true;
  }

  let signOut = async () => {
    setUser(null)
    return await UserService.signOut()
  }

  let reloadUser = async () => {
    try {
      let _user = await UserService.getCurrentUser();
      if (_user) {
        const localUser = UserService.getLocalUser();
        _user.auth = localUser.auth;
        signIn(_user)
      }
    } catch(e) {
    }
  }

  let inject = { 
    user: JSON.stringify(user), 
    signIn, 
    signOut, 
    setUser,
    reloadUser,
  }
  return <AuthContext.Provider value={inject}>{props.children}</AuthContext.Provider>;
}

export const useAuth = () => {
  let auth_context = React.useContext(AuthContext);
  if( typeof(auth_context.user) == 'string' ) {
    auth_context.user = JSON.parse(auth_context.user)
  }
  return auth_context
}

export default {
  Provider,
  useAuth,
}