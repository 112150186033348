import * as React from "react";
import {
	Box,
} from '@mui/material';
import {
	useNavigate,
} from "react-router-dom";
import View from '../ui/View';
import { PhotoProvider, PhotoView } from 'react-photo-view';

function RejectResult(props) {
	const {
		reason,
		image=null,
	} = props;
	return (
		<View sx={styles.resultSection}>
			<View sx={styles.resultSection.item}>
				{reason}
			</View>
			{image && (
				<PhotoProvider>
					<View sx={styles.resultSection.item}>
						<PhotoView src={image}>
							<Box
								component="img"
								src={image}
								sx={styles.resultSection.image}
							/>
						</PhotoView>
					</View>
				</PhotoProvider>
			)}
		</View>
	);
}

export default RejectResult;

const styles = {
	resultSection: {
		fontSize:14,
		p: 1,
		mx:1,
		mb:1,
		borderRadius:2,
		flexDirection:'column',
		background: 'linear-gradient(to right, #ffd4d9, #FFE5E5)',
		color:'error.main',
		item: {
			justifyContent: 'center',
			m:1,
		},
		image: {
			width: 100,
			height: 100,
			borderRadius: 2,
		},
	},
};