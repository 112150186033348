import * as React from "react";
import {
	Box,
	Modal,
	Fade,
	Backdrop,
} from '@mui/material';
import { Player } from '@lottiefiles/react-lottie-player';

function LoadingModal(props) {
	const {
		open,
	} = props

	const style = {
	  position: 'absolute',
	  top: '50%',
	  left: '50%',
	  transform: 'translate(-50%, -50%)',
	  width: 130,
	  bgcolor: 'rgba(255,255,255,0.75)',
	  borderRadius: 8,
	  backdropFilter: 'blur(8px)',
	}

	return (
		<Modal
			open={open}
  		closeAfterTransition
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
  	>
  		<Fade in={open}>
  			<Box sx={style}>
					<Player
						autoplay={true}
						loop={true}
						controls={false}
						src={`/lottie/loading.json`}
						style={{ height: '130px' }}
					/>
				</Box>
			</Fade>
		</Modal>
	);
}

export default LoadingModal;
