import * as React from "react";
import {
	Box,
	Button,
	Container,
} from '@mui/material';
import {
	useNavigate,
} from "react-router-dom";
import View from '../../components/ui/View';
import { useAuth } from '../../providers/AuthProvider';
import {
	currencyFormat,
	getCurrencySymbolByCurrency,
} from '../../services/UtilService';
import CouponService from '../../services/CouponService';

function CouponItem({data, onClick=null}) {
	const { user: currentUser } = useAuth();
	const navigate = useNavigate();

	const statusColor = React.useMemo(() => {
		if (data.status==CouponService.STATUS_ACTIVE) {
			return 'primary.main';
		} else if (data.status==CouponService.STATUS_USED) {
			return 'white';
		} else if (data.status==CouponService.STATUS_EXPIRED) {
			return 'white';
		} else {
			return 'primary.main';
		}
	}, [data]);

	const backgroundColor = React.useMemo(() => {
		if (data.status==CouponService.STATUS_ACTIVE) {
			return 'primary.light';
		} else if (data.status==CouponService.STATUS_USED) {
			return 'black5';
		} else if (data.status==CouponService.STATUS_EXPIRED) {
			return 'black5';
		} else {
			return 'primary.light';
		}
	}, [data]);

	const handlePress = () => {
		if (onClick) {
			onClick();
		} else {
			navigate(-1)	
		}
	}

	return (
		<View
			sx={{
				marginTop: 3,
				height: 100,
				backgroundColor: backgroundColor,
				borderRadius: 5,
				cursor:'pointer',
				transition:'all 0.3s',
				":hover": {
					opacity:0.8
				}
			}}
			onClick={handlePress}
		>
			<>
				<View
					sx={{
						width: 120,
						alignItems: 'center',
						justifyContent:'center',
						borderRight: '3px dashed rgba(255,255,255,0.8)',
						position: 'relative',
					}}
				>
					{data.coupon.type==CouponService.TYPE_AMOUNT && <Box sx={{color: statusColor, marginTop: 1, marginRight: 0.5}} >{getCurrencySymbolByCurrency(currentUser.currency)}</Box>}
					<Box sx={{color: statusColor, fontSize:30, fontWeight:'bold'}} >{currentUser.country=='GHANA' ? parseFloat(data.coupon.amount_cedi_reward) : data.coupon.amount_reward}</Box>
					{data.coupon.type==CouponService.TYPE_PERCENT && <Box sx={{color: statusColor, marginTop: 1, marginLeft: 0.5}} >%</Box>}
					<View sx={{position:'absolute', width:19, height:19, borderRadius:19, backgroundColor:'black1', right:'-11px', top:'-11px'}}></View>
					<View sx={{position:'absolute', width:19, height:19, borderRadius:19, backgroundColor:'black1', right:'-11px', bottom:'-11px'}}></View>
				</View>
				<View
					sx={{
						justifyContent: 'center',
						flexDirection:'column',
						flex:1,
						paddingLeft: 3,
					}}
				>
				{data.coupon?.description && (
					<>
						<Box sx={{color: statusColor, fontWeight:'bold'}} >{data.coupon.name}</Box>
						<Box sx={{color: statusColor}}>{data.coupon?.description}</Box>
					</>
				)}
				{!data.coupon?.description && <Box sx={{color: statusColor}} >{data.coupon.name}</Box>}
				</View>
			</>
		</View>
	);
}

export default CouponItem;