import * as React from "react";
import {
	Box,
	Button,
	Container,
	TextField,
	FormHelperText,
	InputAdornment,
} from '@mui/material';
import {
	useNavigate,
	useSearchParams,
	useLocation,
} from "react-router-dom";
import View from '../../components/ui/View';
import HeaderPrimary from '../../components/layout/HeaderPrimary';
import { useAuth } from '../../providers/AuthProvider';
import { useMessage } from '../../providers/MessageProvider';
import WithdrawService from '../../services/WithdrawService';
import BankSelectionModal from '../../components/account/BankSelectionModal';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import CreditCardRoundedIcon from '@mui/icons-material/CreditCardRounded';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import GradientButton from '../../components/ui/GradientButton';

function BankAccountCreatePage({route, navigation}) {
	const navigate = useNavigate();
	const [selectorVisible, setSelectorVisible] = React.useState(false);
	const [bank, setBank] = React.useState();
	const [accountNumber, setAccountNumber] = React.useState();
	const [accountName, setAccountName] = React.useState();
	const [loading, setLoading] = React.useState(false);
	const [errors, setErrors] = React.useState({
		accountNumber: null,
		accountName: null,
	});
	const message = useMessage();

	const handleBankSelect = (e) => {
		setSelectorVisible(false);
		setBank(e)
	}

	const resetError = (key) => {
		setErrors({
			...errors,
			[key]: null
		})
	}

	const saveBank = async() => {
		if (loading) {
			return;
		}
		try {
			setLoading(true)
			if (!bank || !accountNumber) {
				return false;
			}
			const response = await WithdrawService.createUserBank(bank.id, accountNumber, accountName);
			navigate(-1);
		} catch(e) {
			message.show(e?.message);
		}
		setLoading(false)
	}

	const openBankSelector = () => {
		setSelectorVisible(true)
	}

	return (
		<Container>
			<HeaderPrimary label="Add Bank Account" type="dark" />
			<View sx={{marginTop:4, flexDirection:'column'}}>
				<Box
					onClick={openBankSelector}
					sx={{
						backgroundColor: 'white',
						borderRadius: 3,
						marginBottom: 2,
					}}
				>
					<>
						{bank && (
							<View sx={s.bankSelector}>
								<AccountBalanceRoundedIcon sx={{fontSize:24, color:'black8', mr:1}} />
								<Box sx={{textAlign:'left', flex:1}}>{bank.name}</Box>
								<KeyboardArrowDownRoundedIcon sx={{fontSize:30, color:'black8'}}/>
							</View>
						)}
						{!bank && (
							<View sx={s.bankSelector}>
								<AccountBalanceRoundedIcon sx={{fontSize:24, color:'black8', mr:1}} />
								<Box sx={{textAlign:'left', flex:1}}>Select Bank</Box>
								<KeyboardArrowDownRoundedIcon sx={{fontSize:30, color:'black8'}}/>
							</View>
						)}
					</>
				</Box>
				<TextField
					onChange={t => {
						if (errors.accountNumber) {
							resetError('accountNumber');	
						}
						setAccountNumber(t.target.value.trim());
					}}
					placeholder="Account number"
					InputProps={{
	        	startAdornment: (
	            <InputAdornment position="start">
	              <CreditCardRoundedIcon sx={{color:'black7'}} />
	            </InputAdornment>
	          ),
	        }}
					sx={{mb:2}}
				/>
				{errors.accountNumber && <FormHelperText error>{errors.accountNumber}</FormHelperText>}
				<TextField
					onChange={t => {
						if (errors.accountName) {
							resetError('accountName');	
						}
						setAccountName(t.target.value.trim());
					}}
					placeholder="Name of account"
					InputProps={{
	        	startAdornment: (
	            <InputAdornment position="start">
	              <BadgeOutlinedIcon sx={{color:'black7'}} />
	            </InputAdornment>
	          ),
	        }}
					sx={{mb:2}}
				/>
				{errors.accountName && <FormHelperText error>{errors.accountName}</FormHelperText>}
				<GradientButton
					startIcon={<CheckRoundedIcon />}
					onClick={saveBank}
					loading={loading}
					disabled={!bank || !accountNumber}
					fullWidth
					size="large"
				>Save</GradientButton>
			</View>
			<BankSelectionModal
				open={selectorVisible}
				onClose={() => setSelectorVisible(false)}
				onSelect={handleBankSelect}
			/>
		</Container>
	);
}

export default BankAccountCreatePage;

const s = {
	bankSelector: {
		height: 60,
		cursor:'pointer',
		justifyContent: 'space-between',
		alignItems: 'center',
		px: 2,
	}
};