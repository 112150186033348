import * as React from "react";
import {
	Box,
	Button,
	Collapse,
} from '@mui/material';
import {
	useNavigate,
} from "react-router-dom";
import View from '../ui/View';
import WhiteBox from '../layout/WhiteBox';
import SuccessResult from './SuccessResult';
import PendingResult from './PendingResult';
import RejectResult from './RejectResult';
import OrderDataColumn from './OrderDataColumn';
import { useAuth } from '../../providers/AuthProvider';
import SupportMessageFixedInput from '../trade/SupportMessageFixedInput';
import SupportMessageItem from '../trade/SupportMessageItem';
import OrderCardService from '../../services/OrderCardService';
import { currencyFormat } from '../../services/UtilService';
import ImageService from '../../services/ImageService';
import SupportService from '../../services/SupportService';
import TransactionService from '../../services/TransactionService';
import CardService from '../../services/CardService';
import FlowStepper from './FlowStepper';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import _ from 'lodash';

function DetailOrderCard(props) {
	const {
		orderCard,
	} = props;
	const { user: currentUser } = useAuth();
	const navigate = useNavigate();
	const [supportMessages, setSupportMessages] = React.useState([]);
	const [showDetail, setShowDetail] = React.useState(false);
	const looper = React.useRef();

	const styles = {
		cardInfo: {
			flexDirection: 'row',
			pt: 3,
			pb: 1,
			px: 2,
			image: {
				width: 80,
				height: 50,
				borderRadius: 3,
				mr: 2,
			},
			content: {
				flex: 1,
			},
			cardTitle: {
				fontSize:14,
			},
			cardSubTitle: {
				flex: 1,
				fontSize:12,
			},
			amount: {
				fontWeight:'bold'
			},
		},
		moreButton: {
			fontSize:12,
			color:'black6',
		},
		transferSection: {
			background: 'linear-gradient(to right,#08132e, #29385f)',
			color:'white',
			p: 1,
			fontSize:14,
			mx:1,
			mb:1,
			borderRadius:2,
		},
		transferDetail: {
			whiteSpace:'pre-line',
		},
	}

	React.useEffect(() => {
		loadSupportMessages();
		return () => {
			clearTimeout(looper.current)
		}
	}, []);

	const loadSupportMessages = async() => {
		try {
			const response = await SupportService.getOrderCardSupportMessages(orderCard.id);
			setSupportMessages(_.reverse(response));
			if (
				orderCard.status!=TransactionService.STATUS_SUCCESS 
				&& orderCard.status!=TransactionService.STATUS_FAILED 
			) {
				looper.current = setTimeout(() => {
					loadSupportMessages();
				}, 15000)	
			}
		} catch(e) {
		}
	}

	const handleSupportSuccess = () => {
		loadSupportMessages();
	}

	const stepperIcons = React.useMemo(() => {
		let _icons = [];
		if (orderCard.type==CardService.TYPE_GIFTCARD) {
			_icons = [
				{icon: '/image/step_plan.svg', name:'Submit'},
				{icon: '/image/step_pending.svg', name:'Processing'},
				{icon: orderCard.status==OrderCardService.STATUS_FAILED ? '/image/step_fail.svg' : '/image/step_success.svg', name: orderCard.status==OrderCardService.STATUS_FAILED ? 'Rejected' : 'Success'},
			];
		} else if (orderCard.type==CardService.TYPE_FUNDS) {
			_icons = [
				{icon: '/image/step_pending.svg', name:'Preparing'},
				{icon: '/image/step_exchange.svg', name:'Transfer'},
				{icon: orderCard.status==OrderCardService.STATUS_FAILED ? '/image/step_fail.svg' : '/image/step_success.svg', name: orderCard.status==OrderCardService.STATUS_FAILED ? 'Rejected' : 'Success'},
			];
		}
		return _icons;
	}, [orderCard])

	const currentStepIndex = React.useMemo(() => {
		let current = 1;
		if (orderCard.type==CardService.TYPE_GIFTCARD) {
			switch (orderCard.status) {
				case OrderCardService.STATUS_PENDING:
				case OrderCardService.STATUS_CHECKING:
					current = 2;
					break;
				case OrderCardService.STATUS_COMPLETED:
				case OrderCardService.STATUS_FAILED:
					current = 3;
					break;
			}

		} else if (orderCard.type==CardService.TYPE_FUNDS) {
			switch (orderCard.status) {
				case OrderCardService.STATUS_PENDING:
					current = 1;
					current = 1;
					break;
				case OrderCardService.STATUS_TRANSFERING:
				case OrderCardService.STATUS_TRANSFER_CHECKING:
					current = 2;
					break;
				case OrderCardService.STATUS_COMPLETED:
				case OrderCardService.STATUS_FAILED:
					current = 3;
					break;
			}
		}
		return current;
	}, [orderCard])

	return (
		<>
			<WhiteBox sx={{my: 3}}>
				<Box sx={{borderBottom:'1px solid transparent', borderBottomColor:'black1',}}>
					<FlowStepper
						steps={stepperIcons}
						currentStep={currentStepIndex}
					/>
					{orderCard.status==OrderCardService.STATUS_FAILED && <RejectResult
						reason={orderCard.failed_reason}
						image={orderCard.failed_images.length>0 ? ImageService.getVisitUrl(orderCard.failed_images[0].public_url, currentUser) : null}
					/>}
				</Box>

				<View sx={styles.cardInfo}>
					<Box sx={styles.cardInfo.content}>
						<Box sx={styles.cardInfo.cardTitle}>{orderCard.card.name}, {orderCard.card_balance}</Box>
						<Box sx={{...styles.cardInfo.cardSubTitle, color: 'black5'}} >
							#{orderCard.display_id.toUpperCase()}
						</Box>
					</Box>
					<Box sx={styles.cardInfo.amount}>
						{`${currentUser.currency_symbol}${currencyFormat(orderCard.amount_total)}`}
					</Box>
				</View>
				<Collapse in={showDetail}>
					<OrderDataColumn title="ID" value={`#${orderCard.display_id.toUpperCase()}`} />
					<OrderDataColumn title="Amount" value={`${orderCard.country.currency_symbol}${orderCard.card_balance} | ${orderCard.rate}`} />
					<OrderDataColumn title="Card Value" value={`${currentUser.currency_symbol}${currencyFormat(orderCard.amount_card)}`} />
					{orderCard.amount_bonus>0 && <OrderDataColumn title="Bonus Value" value={`${currentUser.currency_symbol}${currencyFormat(orderCard.amount_bonus)}`} />}
					{orderCard.card_data && <OrderDataColumn title="Ecode" value={orderCard.card_data} />}
					<OrderDataColumn title="Date" value={orderCard.created_at} />
				</Collapse>
				<Button sx={styles.moreButton} fullWidth onClick={() => setShowDetail(!showDetail)}>
					{!showDetail && (<>Detail <KeyboardArrowDownRoundedIcon /></>)}
					{showDetail && (<>Hide <KeyboardArrowDownRoundedIcon sx={{transform:'rotate(180deg)'}} /></>)}
				</Button>
			</WhiteBox>

			{supportMessages.map(i => <SupportMessageItem data={i} key={`support_item_${i.id}`} />)}
			<SupportMessageFixedInput
				onSubmit={handleSupportSuccess}
				data={orderCard}
				type="order"
			/>

		</>
	);
}

export default DetailOrderCard;

