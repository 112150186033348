import * as React from "react";
import {
	Box,
	Button,
	Container,
	TextField,
	FormHelperText,
	InputAdornment,
} from '@mui/material';
import {
	useNavigate,
	useSearchParams,
	useLocation,
} from "react-router-dom";
import View from '../../components/ui/View';
import HeaderPrimary from '../../components/layout/HeaderPrimary';
import { useAuth } from '../../providers/AuthProvider';
import { useMessage } from '../../providers/MessageProvider';
import Loading from '../../components/ui/Loading';
import WhiteBox from '../../components/layout/WhiteBox';
import SupportService from '../../services/SupportService';
import SupportItem from '../../components/trade/SupportItem';
import EmptyList from '../../components/layout/EmptyList';
import { useMotionValueEvent, useScroll } from "framer-motion";
import TouchableOpacity from '../../components/ui/TouchableOpacity';

function SupportPage(props) {
	const [loading, setLoading] = React.useState(false);
	const [data, setData] = React.useState([]);
	const page = React.useRef(1);
	const hasMore = React.useRef(true);
	const lockBottomReach = React.useRef(true);
	const [refreshing, setRefreshing] = React.useState(false);
	const { scrollYProgress } = useScroll();
	const navigate = useNavigate();

	React.useEffect(() => {
		setLoading(true);
		loadSupports();
		return () => {}
	}, [])

	const loadSupports = async(reload=false) => {
		try {
			const response = await SupportService.getAllSupports(page.current);
			let finalList = [];
			if (reload) {
				finalList = response.data;
			} else {
				finalList = [...data, ...response.data];	
			}
			setData(finalList);
			lockBottomReach.current = false;
			hasMore.current = !!response.next_page_url;
		} catch(e) {
		}
		setRefreshing(false);
		setLoading(false);
	}

	const loadMore = async() => {
		if (lockBottomReach.current || !hasMore.current) {
			return;
		}
		setLoading(true);
		lockBottomReach.current = true;
		page.current++;
		loadSupports();
	}

	const reloadData = async() => {
		setRefreshing(true);
		hasMore.current = true;
		page.current = 1;
		loadSupports(true);
	}

	const toTransactionDetailScreen = (item) => {
		if (!item.object?.transaction) {
			return false;
		}
		if (item.object_type=='App\\Models\\Withdrawal') {
			navigate(`/transactions/${item.object.transaction.id}`)
		} else if(item.object_type=='App\\Models\\OrderCard') {
			navigate(`/transactions/${item.object.transaction.id}`)
		}
	}

	useMotionValueEvent(scrollYProgress, "change", (latest) => {
		if (latest>0.8) {
			loadMore();
		}
	})

	return (
		<Container>
			<HeaderPrimary label="Supports" type="dark" />
			<Box sx={{mt:3}}>
				{data.length==0 && !loading && (
					<EmptyList sx={{mt: 20}}/>
				)}
				{data.length>0 && data.map(item => (
					<TouchableOpacity key={`support_item_${item.id}`}>
						<SupportItem
							data={item}
							marginBottom={3}
							onClick={() => toTransactionDetailScreen(item)}
						/>
					</TouchableOpacity>
				))}
				{loading && data.length==0 && (<Loading />)}
			</Box>
		</Container>
	);
}

export default SupportPage;