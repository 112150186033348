import * as React from "react";
import {
	Box,
	Button,
	Container,
} from '@mui/material';
import {
	useNavigate,
	useSearchParams,
} from "react-router-dom";
import View from '../../components/ui/View';
import UserService from '../../services/UserService';
import CouponItem from '../../components/account/CouponItem';
import Loading from '../../components/ui/Loading';
import HeaderPrimary from '../../components/layout/HeaderPrimary';

function CouponSelectionPage(props) {
	const [data, setData] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const navigate = useNavigate();
	let [searchParams, setSearchParams] = useSearchParams();

	React.useEffect(() => {
		loadUserCoupons();
	}, [])

	const selectCoupon = (_coupon) => {
		navigate(`/gift-cards/submit?cardId=${searchParams.get('cardId')}&cardCategoryId=${searchParams.get('cardCategoryId')}`, {
			state: {
				selectCoupon: _coupon
			},
			replace: true,
		});
	}

	const loadUserCoupons = async() => {
		setLoading(true);
		try {
			const response = await UserService.getAllAvailableCoupons();
			setData(response);
		} catch(e) {
		}
		setLoading(false);
	}

	return (
		<Container>
			<HeaderPrimary label="Select Coupon" type="dark" />
			<View style={{mx: 2, flexDirection:'column'}}>
				{loading && <Loading />}
				{data.map(i => (
					<CouponItem
						data={i}
						onClick={() => selectCoupon(i)}
						key={`coupon_item_${i.id}`}
					/>
				))}
			</View>
		</Container>
	)
}

export default CouponSelectionPage