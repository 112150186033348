import Request from './Request';

export default {
	TYPE_GIFTCARD: 1,
	TYPE_FUNDS: 2,
	async getAllCards() {
		return Request.get('/api/config/rates', {});
	},
	async getCardDetail(id) {
		return Request.get(`/api/cards/${id}`, {});
	},
	async sellCard(card_category_id, card_amount, total_value, ecode=null, images=[], use_coupon_id=null) {
		return Request.postFormData('/api/cards', {
			card_category_id,
			card_amount,
			total_value,
			ecode,
			images,
			use_coupon_id,
		})
	},
}