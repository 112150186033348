import * as React from "react";
import {
	Box,
	Button,
	Container,
} from '@mui/material';
import {
	useNavigate,
	useSearchParams,
	useLocation,
} from "react-router-dom";
import View from '../../components/ui/View';
import HeaderPrimary from '../../components/layout/HeaderPrimary';
import { useAuth } from '../../providers/AuthProvider';
import { useMessage } from '../../providers/MessageProvider';
import BankAccountItem from '../../components/trade/BankAccountItem';
import WithdrawService from '../../services/WithdrawService';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Loading from '../../components/ui/Loading';

function BankAccountsPage(props) {
	const [data, setData] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	React.useEffect(() => {
		loadUserBanks();
	}, [])

	React.useEffect(() => {
		if (location.state?.createdBank) {
			loadUserBanks();
		}
	}, [location]);

	const selectBank = (_bank) => {
		navigate('/withdrawal/submit', {
			state: {
				selectedBank: _bank
			},
			replace: true
		});
	}

	const toBankCreate = () => {
		navigate('/bank-accounts/submit')
	}

	const loadUserBanks = async() => {
		try {
			setLoading(true)
			const response = await WithdrawService.getUserBanks();
			setData(response);
		} catch(e) {
		}
		setLoading(false)
	}

	return (
		<Container>
			<HeaderPrimary label="Banks" type="dark" />
			<View style={{flexDirection:'column'}}>
				{loading && <Loading />}
				{data.map(i => (
					<BankAccountItem data={i} onClick={() => selectBank(i)} key={`bank_${i.id}`}/>
				))}
				<Button
					variant="outlined"
					startIcon={<AddRoundedIcon />}
					fullWidth
					onClick={toBankCreate}
					size="large"
					sx={{
						borderWidth: 0,
						marginBottom: 10,
						height: 100,
						backgroundColor:'white',
						mt:2,
					}}
				>
					Add Bank Account
				</Button>
			</View>
		</Container>
	);
}

export default BankAccountsPage