import * as React from "react";
import {
	Box,
	Button,
	Typography,
	TextField,
	FormHelperText,
	InputAdornment,
	Container,
} from '@mui/material';
import { useAuth } from '../../providers/AuthProvider';
import { useMessage } from '../../providers/MessageProvider';
import UserService from '../../services/UserService';
import AuthLayout from '../../components/layout/AuthLayout';
import {
	Link,
	useNavigate,
} from "react-router-dom";
import _ from 'lodash';
import GradientButton from '../../components/ui/GradientButton';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import PermIdentityRoundedIcon from '@mui/icons-material/PermIdentityRounded';
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';

function LoginPage(props) {
	const auth = useAuth();
	const message = useMessage();
	const navigate = useNavigate();
	const [username, setUsername] = React.useState('');
	const [password, setPassword] = React.useState('');
	const [loading, setLoading] = React.useState(false);
	const [errors, setErrors] = React.useState({
		username: null,
		password: null,
	});

	const resetError = (key) => {
		setErrors({
			...errors,
			[key]: null
		})
	}

	const onSignIn = async() => {
		try {
			let errorBag = {};
			if (!username) {
				errorBag['username'] = 'Email or phone number can not be empty';
			}
			if (!password) {
				errorBag['password'] = 'Password can not be empty';
			}
			if (!_.isEmpty(errorBag)) {
				setErrors({
					...errors,
					...errorBag,
				})
				return;
			}
			setLoading(true)
			const _user = await UserService.signIn(username, password)
			auth.signIn(_user);
			navigate('/', {replace: true})
		} catch(e) {
			message.show(e?.message);
			setLoading(false)
		}
	}

	const toSignUpPage = () => {
		navigate('/signup');
	}

	return (
		<AuthLayout title="Sign In">
			<TextField 
				fullWidth
				placeholder="Email or Phone number"
				onChange={(event) => {
			    resetError('username');
					setUsername(event.target.value.trim());
			  }}
			  InputProps={{
        	startAdornment: (
            <InputAdornment position="start">
              <PermIdentityRoundedIcon sx={{color:'black7'}} />
            </InputAdornment>
          ),
        }}
			/>
			{errors.username && <FormHelperText error>{errors.username}</FormHelperText>}

			<TextField 
				fullWidth
				type="password"
				placeholder="Password"
				onChange={(event) => {
					resetError('password');
					setPassword(event.target.value.trim());
			  }}
			  InputProps={{
        	startAdornment: (
            <InputAdornment position="start">
              <LockOpenRoundedIcon sx={{color:'black7'}} />
            </InputAdornment>
           )
        }}
        sx={{mt:{xs:2, md:3}}}
			/>
			{errors.password && <FormHelperText error>{errors.password}</FormHelperText>}

			<Box sx={{mt:2, textAlign:'right'}}>
				<Box sx={{}} component={Link} to="/reset-password">Forgot Password?</Box>
			</Box>
			
			<GradientButton
				size="large"
				fullWidth
				endIcon={<NavigateNextRoundedIcon />}
				sx={{mt:{xs:3, md:4}}}
				loading={loading}
				onClick={onSignIn}
			>Next</GradientButton>
			<Button
				fullWidth
				size="large"
				sx={{mt:2, color:'black8'}}
				onClick={toSignUpPage}
			>New User?&nbsp;<b>Sign Up</b></Button>
		</AuthLayout>
	)
}

export default LoginPage