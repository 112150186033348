import * as React from "react";
import {
	Box,
	Button,
	Container,
	Avatar,
	Skeleton,
} from '@mui/material';
import {
	Link,
	useNavigate,
} from "react-router-dom";
import View from '../../components/ui/View';
import HeaderPrimary from '../../components/layout/HeaderPrimary';
import { useAuth } from '../../providers/AuthProvider';
import { useMessage } from '../../providers/MessageProvider';
import SystemService from '../../services/SystemService';
import { getCurrencySymbolByCurrency } from '../../services/UtilService';
import WhiteBox from '../../components/layout/WhiteBox';
import TouchableOpacity from '../../components/ui/TouchableOpacity';

function RateItem({data}) {
	const navigate = useNavigate();

	const sellCard = () => {
		navigate(`/gift-cards/submit?cardId=${data.card_category.card_id}&cardCategoryId=${data.card_category.id}`)
	}

	return (
		<TouchableOpacity onClick={sellCard}>
			<View sx={css.rateItem}>
				<Box component="img" src={data.card_category.card.image} sx={css.rateImage}/>
				<View sx={css.rateNames}>
					<Box >{data.card_category.card.name}</Box>
					<Box sx={css.rateContent}>{data.card_category.display_name}</Box>
				</View>
				<Box sx={css.rateNumber}>
					{getCurrencySymbolByCurrency(data.currency)}
					{data.rate}
				</Box>
			</View>
		</TouchableOpacity>
	);
}

function TopRatePage(props) {
	const [rates, setRates] = React.useState([]);
	const [loading, setLoading] = React.useState(false);

	React.useEffect(() => {
		loadRates();
	}, [])

	const loadRates = async() => {
		try {
			setLoading(true);
			const response = await SystemService.getTopRates();
			setRates(response);
		} catch(e) {
		}
		setLoading(false);
	}

	return (
		<Container>
			<HeaderPrimary label="Top Rates" type="dark" />
			<WhiteBox sx={{my:3}}>
			{!loading && rates.map(item => <RateItem data={item} key={`rate_item_${item.id}`} />)}
			{loading && (
				<View sx={{flex: 1, mx: 3, flexDirection:'column'}}>
					<Skeleton sx={{width: "100%", py:1, mb:2}} />
					<Skeleton sx={{width: "100%", py:1, mb:2}} />
					<Skeleton sx={{width: "100%", py:1, mb:2}} />
					<Skeleton sx={{width: "100%", py:1, mb:2}} />
					<Skeleton sx={{width: "100%", py:1, mb:2}} />
					<Skeleton sx={{width: "100%", py:1, mb:2}} />
					<Skeleton sx={{width: "100%", py:1, mb:2}} />
					<Skeleton sx={{width: "100%", py:1, mb:2}} />
				</View>
			)}
			</WhiteBox>
		</Container>
	);
}

export default TopRatePage;

const css = {
	rateItem: {
		alignItems: 'center',
		mx: 2,
		height: 100,
	},
	rateImage: {
		width: 50,
		height: 50,
		borderRadius: 8,
		marginRight: 2,
		objectFit:'cover',
	},
	rateNames: {
		flex: 1,
		flexDirection:'column',
		overflow:'hidden',
	},
	rateNumber: {
		fontWeight:'bold',
	},
	rateContent: {
		marginTop: 1,
		fontSize:12,
		color: 'black5',
		overflow:'hidden',
		whiteSpace:'nowrap',
		textOverflow:'ellipsis',
	}
};