import * as React from "react";
import {
	Box,
	Button,
	Typography,
	TextField,
	FormHelperText,
	InputAdornment,
	Container,
	IconButton,
} from '@mui/material';
import { useAuth } from '../../providers/AuthProvider';
import { useMessage } from '../../providers/MessageProvider';
import UserService from '../../services/UserService';
import AuthLayout from '../../components/layout/AuthLayout';
import {
	Link,
	useNavigate,
} from "react-router-dom";
import _ from 'lodash';
import GradientButton from '../../components/ui/GradientButton';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import PermIdentityRoundedIcon from '@mui/icons-material/PermIdentityRounded';
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';
import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';
import SendToMobileOutlinedIcon from '@mui/icons-material/SendToMobileOutlined';
import DialpadRoundedIcon from '@mui/icons-material/DialpadRounded';

function FindPasswordPage(props) {
	const auth = useAuth();
	const message = useMessage();
	const navigate = useNavigate();
	const [identity, setIdentity] = React.useState('');
	const [password, setPassword] = React.useState('');
	const [confirmPassword, setConfirmPassword] = React.useState('');
	const [verificationCode, setVerificationCode] = React.useState('');
	const [step ,setStep] = React.useState(1);
	const [loading, setLoading] = React.useState(false);
	const [verifing, setVerifing] = React.useState(false);
	const [countDown, setCountDown] = React.useState(null);
	const [errors, setErrors] = React.useState({
		identity: null,
		password: null,
		confirmPassword: null,
		verificationCode: null,
	});
	const timer = React.useRef();

	React.useEffect(() => {
		if (countDown>0) {
			timer.current = setTimeout(() => {
				setCountDown(countDown-1);
			}, 1000);
		} else {
			setCountDown(null);
			clearTimeout(timer.current);
		}
	}, [countDown]);

	const sendVerificationCode = async() => {
		try {
			let errorBag = {};
			if (!identity) {
				errorBag['identity'] = 'Email or Phone number can not be empty';
			}
			if (!_.isEmpty(errorBag)) {
				setErrors({
					...errors,
					...errorBag,
				})
				return;
			}
			setLoading(true);
			const _result = await UserService.sendResetPasswordCode(identity)
			setCountDown(60);
			toStepTwo();
		} catch(e) {
			message.show(e?.message);
		}
		setLoading(false);
	}

	const toStepTwo = () => {
		setStep(2);
	}

	const resetError = (key) => {
		setErrors({
			...errors,
			[key]: null
		})
	}

	const verificationCodeRightComponent = React.useMemo(() => {
		if (countDown>0) {
			return (<InputAdornment position="end"><span>{`${countDown}s`}</span></InputAdornment>);
		} else {
			return (<InputAdornment position="end"><IconButton onClick={sendVerificationCode}><SendToMobileOutlinedIcon /></IconButton></InputAdornment>);
		}
	}, [countDown]);

	const resetPassword = async() => {
		try {
			let errorBag = {};
			if (!verificationCode) {
				errorBag['verificationCode'] = 'Verification code can not be empty';
			}
			if (password.trim().length<5 || password.trim().length>30) {
				errorBag['password'] = 'Password at least 5 characters';
			}
			if (!password) {
				errorBag['password'] = 'Password can not be empty';
			}
			if (password != confirmPassword) {
				errorBag['confirmPassword'] = 'Password doesn\'t match';
			}
			if (!_.isEmpty(errorBag)) {
				setErrors({
					...errors,
					...errorBag,
				})
				return;
			}
			setLoading(true);
			const _result = await UserService.resetPasswordByVerificationCode(identity, verificationCode, password);
			message.showSuccess('Password successfully reset.');
			navigate('/signin');
		} catch(e) {
			message.show(e?.message);
		}
		setLoading(false);
	}


	return (
		<AuthLayout title="Reset Password">
			{step==1 && (
				<>
					<TextField
						fullWidth
						onChange={t => {
							resetError('identity');
							setIdentity(t.target.value);
						}}
						placeholder="Email or Phone number"
						InputProps={{
		        	startAdornment: (
		            <InputAdornment position="start">
		              <PermIdentityRoundedIcon sx={{color:'black7'}} />
		            </InputAdornment>
		          ),
		        }}
						error={errors.identity}
					/>
					{errors.identity && <FormHelperText error>{errors.identity}</FormHelperText>}

					<GradientButton
						onClick={sendVerificationCode}
						loading={loading}
						sx={{mt:{xs:2, md:3}}}
						endIcon={<NavigateNextRoundedIcon />}
						fullWidth
						size="large"
					>
						Next
					</GradientButton>
					<Button
						onClick={() => navigate(-1)}
						fullWidth
						size="large"
						sx={{mt:{xs:2, md:3, color:'black8'}}}
					>
						Back
					</Button>
				</>
			)}
			
			{step==2 && (
				<>
					<Box sx={{my:3, textAlign: 'center'}}>
							Verification code has been sent
					</Box>

					<TextField
						sx={{mt:{xs:2, md:3}}}
						fullWidth
						onChange={t => {
							resetError('verificationCode');
							setVerificationCode(t.target.value);
						}}
						placeholder="Verification Code"
						type="number"
						InputProps={{
		        	startAdornment: (
		            <InputAdornment position="start">
		              <DialpadRoundedIcon sx={{color:'black7'}} />
		            </InputAdornment>
		          ),
		          endAdornment: (verificationCodeRightComponent)
		        }}
					/>
					{errors.verificationCode && <FormHelperText error>{errors.verificationCode}</FormHelperText>}

					<TextField
						sx={{mt:{xs:2, md:3}}}
						fullWidth
						onChange={t => {
							resetError('password');
							setPassword(t.target.value);
						}}
						placeholder="Password"
						type="password"
						InputProps={{
		        	startAdornment: (
		            <InputAdornment position="start">
		              <LockOpenRoundedIcon sx={{color:'black7'}} />
		            </InputAdornment>
		          ),
		        }}
					/>
					{errors.password && <FormHelperText error>{errors.password}</FormHelperText>}

					<TextField
						sx={{mt:{xs:2, md:3}}}
						fullWidth
						onChange={t => {
							resetError('confirmPassword');
							setConfirmPassword(t.target.value);
						}}
						placeholder="Confirm Password"
						type="password"
						InputProps={{
		        	startAdornment: (
		            <InputAdornment position="start">
		              <LockOpenRoundedIcon sx={{color:'black7'}} />
		            </InputAdornment>
		          ),
		        }}
					/>
					{errors.confirmPassword && <FormHelperText error>{errors.confirmPassword}</FormHelperText>}

					<GradientButton
						onClick={resetPassword}
						loading={loading}
						sx={{mt:{xs:2, md:3}}}
						fullWidth
						size="large"
					>
						Reset Password
					</GradientButton>
					<Button
						onClick={() => navigate(-1)}
						fullWidth
						size="large"
						sx={{mt:{xs:2, md:3, color:'black8'}}}
					>
						Back
					</Button>
				</>
			)}
		</AuthLayout>
	)
}

export default FindPasswordPage