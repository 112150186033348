import * as React from "react";
import {
  Box,
  Stack,
  Typography,
} from '@mui/material';
import {
  useNavigate,
} from "react-router-dom";
import View from '../ui/View';
import WhiteBox from '../layout/WhiteBox';
import SupportService from '../../services/SupportService';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import { setClipBoard } from '../../services/UtilService';
import { useMessage } from '../../providers/MessageProvider';

function SupportMessageItem(props) {
  const {
    data,
    onClick=null,
  } = props;
  const message = useMessage();

  const css = {
    chatItem: {
      mb:2,
      display:'flex',
    },
    chatPop: {
      flexDirection: 'column',
      maxWidth:'85%',
      boxShadow: '0 2px 1px rgba(53,57,65,0.1)',
      position:'relative',
      corner: {
        display:'block',
        border:'5px solid',
        content: '""',
        width: 0,
        height: 0,
        position: 'absolute',
      }
    },
  }

  const copy = (text) => {
    setClipBoard(text);
    message.showSuccess('Copied');
  }

  return (
    <Box
      onClick={onClick}
      sx={{
        ...css.chatItem,
        flexDirection: data.send_type==0 ? 'row-reverse' : 'row',
      }}
    >
      <WhiteBox 
        sx={{
          ...css.chatPop,
          borderRadius: data.send_type==0 ? '9px 0 9px 9px' : '0 9px 9px 9px',
          backgroundColor: data.send_type==0 ? '#e2fec9' : '#fff',
        }}
      >
        <Box
          sx={{
            ...css.chatPop.corner,
            borderColor: data.send_type==0 ? '#e2fec9 transparent transparent #e2fec9' : '#fff #fff transparent transparent',
            left: data.send_type==0 ? 'auto' : '-10px',
            right: data.send_type==0 ? '-10px' : 'auto',
          }}
        />
        <Box sx={{p:1.3,}}>
          {data.extra_data && (
            <Stack 
              sx={{
                borderRadius: 2,
                p:1,
                pb:1.5,
                background: 'linear-gradient(135deg, #053385, #053385, #0a5fbc)',
                mb:1,
                border: '1px solid #053385',
              }}
              spacing={1}
            >
              {data.extra_data.meta.map((_meta, _key) => (
                <Box sx={{}} key={`meta_${_key}`}>
                  <Typography sx={{color: 'black5', fontSize: 10}} variant="caption">{_meta.key} (Tap to Copy)</Typography>
                  <Typography
                    sx={{
                      color: 'black1',
                      textDecoration:'underline dashed', 
                      cursor:'pointer'
                    }}
                    variant="body2"
                    onClick={() => copy(_meta.value)}
                  >{_meta.value}</Typography>
                </Box>
              ))}
            </Stack>
          )}
          <Box sx={{fontSize:14, whiteSpace:'pre-line'}}>{data.message}</Box>
          {data.images?.length>0 && (
            <PhotoProvider>
              <View sx={{mt:2}}>
                {data.images.map(_image => (
                  <PhotoView src={_image.public_url} key={`message_image_${_image.id}`}>
                    <Box
                      component="img"
                      src={_image.public_url}
                      sx={{
                        width: 100,
                        height: 100,
                        borderRadius: 2,
                        objectFit:'cover',
                      }}
                    />
                  </PhotoView>
                ))}
              </View>
            </PhotoProvider>
          )}
          <Box sx={{fontSize:12, color:'black5', mt:1, textAlign:'right'}}>{data.created_at}</Box>
        </Box>
      </WhiteBox>
    </Box>
  );
}

export default SupportMessageItem;