import * as React from "react";
import {
	Box,
} from '@mui/material';
import {
	useNavigate,
} from "react-router-dom";
import View from '../ui/View';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';

function SuccessResult(props) {
	return (
		<View sx={styles.resultSection}>
			<VerifiedOutlinedIcon sx={styles.resultSection.icon} />
			<Box sx={styles.resultSection.mainTitle} >Success</Box>
		</View>
	);
}

export default SuccessResult;

const styles = {
	resultSection: {
		py: 3,
		borderRadius: '10px 10px 0 0',
		backgroundColor: 'success.main',
		alignItems: 'center',
		flexDirection:'column',
		mainTitle: {
			color: 'white',
			fontWeight:'bold',
			fontSize:20,
		},
		icon: {
			color: 'rgba(255,255,255,0.7)',
			mb: 1,
			fontSize:40,
		},
		image: {
			width: 100,
			height: 100,
			borderRadius: 2,
		},
	},
};