import * as React from "react";
import {
	Box,
	Container,
	Typography,
	Modal,
	IconButton,
	TextField,
	InputAdornment,
	Fade,
} from '@mui/material';
import {
	useNavigate,
} from "react-router-dom";
import { useMessage } from '../../providers/MessageProvider';
import Loading from '../ui/Loading';
import EmptyList from '../layout/EmptyList';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ManageSearchRoundedIcon from '@mui/icons-material/ManageSearchRounded';
import View from '../ui/View';
import WhiteBox from '../layout/WhiteBox';
import CardService from '../../services/CardService';

function SelectGiftCardCategoryModal(props) {
	const message = useMessage();
	const [searchKeyword, setSearchKeyword] = React.useState('');
	const [loading, setLoading] = React.useState(false);
	const [categories, setCategories] = React.useState([]);
	const {
		card,
		onClose,
		open,
	} = props;

	React.useEffect(() => {
		if (open && card) {
			loadCardDetail();
		}
	}, [open, card]);

	const loadCardDetail = async() => {
		try {
			setCategories(card.active_categories.filter(i => !!i.current_rate))
		} catch(e) {
		}
	}

	const filtersCategories = React.useMemo(() => {
		if (categories) {
			if (searchKeyword) {
				return categories.filter(i => i.name.toLowerCase().indexOf(searchKeyword.toLowerCase())>-1);
			} else {
				return categories;
			}
		} else {
			return [];
		}
	}, [categories, searchKeyword]);

	return (
		<Modal
			sx={styles.modalLayer}
			{...props}
		>
			<Fade in={open}>
				<Box sx={styles.modalContent}>
					{card && (
						<View sx={styles.header}>
							<Box sx={styles.desc}>Select category of {card.name}</Box>
							<IconButton
								onClick={onClose}
								sx={{fontSize:34}}
							><CloseRoundedIcon /></IconButton>
						</View>
					)}
					
					<Box sx={styles.categoryLayer}>
						<WhiteBox sx={styles.categories}>
							{filtersCategories.map(i => (
								<Box key={`category_item_${i.id}`} onClick={() => props.onSelect(i)} >
									<View sx={styles.categoryItem}>
										<Box component="img" src={i.country.image} sx={styles.categoryItem.image}  />
										<Box sx={{flex: 1, fontSize:14}}>{i.display_name}</Box>
									</View>
								</Box>
							))}

							{loading && <Loading />}
							{!loading && filtersCategories.length==0 && <EmptyList />}
						</WhiteBox>
					</Box>
				</Box>
			</Fade>
		</Modal>
	);
}
export default SelectGiftCardCategoryModal;

const styles = {
	modalLayer: {
	},
	modalContent: {
		display:'flex',
		position:'absolute',
		bottom:0,
		left:{xs:0, md:'50%'},
		right:{xs:0, md:'inherit'},
		backgroundColor: '#f5f7fa',
		borderRadius:'12px 12px 0 0',
		flexDirection:'column',
		width:{xs:'100%', md:800},
		transform:{xs:'translateX(0)', md:'translateX(-50%) !important'},
		outline:'none',
	},
	header: {
		borderRadius:'12px 12px 0 0',
		p: 2,
		backgroundColor: 'primary.main',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	cardImage: {
		width: 55,
		height: 35,
		borderRadius: 1,
	},
	desc: {
		color: '#FFFFFF',
		fontSize:13,
	},
	searchInput: {
		mt: 3,
		'& .MuiOutlinedInput-root': {
			backgroundColor: 'rgba(255,255,255,0.3)',
		},
		'& .MuiOutlinedInput-input': {
			color:'white',
		}
	},
	categoryLayer: {
		flex:1,
		maxHeight:500,
		overflow:'scroll',
	},
	categories: {
		flexDirection: 'column',
		mx: 2,
		my: 3,
		minHeight:300,
	},
	categoryItem: {
		cursor:'pointer',
		flexDirection: 'row',
		alignItems: 'center',
		py: 2.5,
		px: 2,
		border: '1px solid #f3f4f5',
		image: {
			width: 18,
			height: 18,
			mr: 2,
		}
	},
};