import * as React from "react";
import {
	Box,
	Button,
} from '@mui/material'

function PinCheckPage(props) {

	return (
		<>
			
		</>
	)
}

export default PinCheckPage