import * as React from 'react';
import View from '../ui/View';
import {
  Box,
} from '@mui/material';

function Level(props) {
  const {
    level,
    size='normal',
    onClick=null,
  } = props;

  const styles = React.useMemo(() => {
    if (size=='small') {
      return {
        levelLayer: {
          width: 30,
          height: 16,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#20232B',
          borderRadius: 3,
        },
        levelIcon: {
          width: 26,
          height: 10,
        },
      };
    } else {
      return {
        levelLayer: {
          width: 38,
          height: 20,
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#20232B',
          borderRadius: 4,
        },
        levelIcon: {
          width: 31,
          height: 13,
        },
      };
    }
  }, [size]);

  return (
    <View
      onClick={() => {
        if (onClick) {
          onClick()
        }
      }}
      style={{...styles.levelLayer, ...props.sx}}
    >
      {level==1 && <Box component="img" src={'/image/level1.png'} style={styles.levelIcon} /> }
      {level==2 && <Box component="img" src={'/image/level2.png'} style={styles.levelIcon} /> }
      {level==3 && <Box component="img" src={'/image/level3.png'} style={styles.levelIcon} /> }
      {level==4 && <Box component="img" src={'/image/level4.png'} style={styles.levelIcon} /> }
    </View>
  );
}

export default Level;