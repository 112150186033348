import React from 'react';
import {
	Box,
	Button,
} from '@mui/material';
import {
	Link,
	useNavigate,
} from "react-router-dom";
import TransactionService from '../../services/TransactionService';
import { currencyFormat } from '../../services/UtilService';
import { useAuth } from '../../providers/AuthProvider';
import View from '../../components/ui/View';
import GradeOutlinedIcon from '@mui/icons-material/GradeOutlined';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import BlurCircularOutlinedIcon from '@mui/icons-material/BlurCircularOutlined';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import NotInterestedRoundedIcon from '@mui/icons-material/NotInterestedRounded';
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';

function TransactionItem(props) {
	const {
		data,
		showBackgroundColor=false,
	} = props;
	const { user: currentUser } = useAuth();
	const navigate = useNavigate();

	const styles = {
		item: {
			px: 1.5,
			py: 3,
			borderBottom:'1px solid #E6e9ed',
			cursor:'pointer',
			transition:'all 0.3s',
			':hover': {
				backgroundColor:'black1'
			},
		},
		iconLayer: {
			height: 50,
			width: 50,
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: 'primary.light',
			borderRadius: 50,
		},
		iconLayerError: {
			height: 50,
			width: 50,
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: 'error.light',
			borderRadius: 50,
		},
		mainContent: {
			flex: 1,
			mx: 2,
			flexDirection:'column',
			overflow:'hidden',
		},
		labels: {
			mt: 1,
			color: 'black4',
			fontSize:12,
		},
		infoContent: {
			alignItems: 'flex-end',
			flexDirection:'column'
		},
		cardIcon: {
			width: 45,
			height: 45,
			borderRadius: 45,
			objectFit: 'cover',
		},
		redDot: {
			px:1,
			borderRadius:10,
			color:'white',
			fontSize:12,
			backgroundColor:'error.main',
			display:'flex',
			alignItems:'center',
			gap:'4px',
		},
	};

	const toTransactionDetail = () => {
		navigate(`/transactions/${data.id}`, {})
	}

	return (
		<Box onClick={toTransactionDetail} sx={{backgroundColor: showBackgroundColor ? '#FFF' : 'transparent'}}>
			<View sx={styles.item}>
				{data.type==TransactionService.TYPE_SELLCARD && <View sx={{...styles.iconLayer, backgroundColor: 'black4'}}><Box component="img" src={data.object?.image} sx={styles.cardIcon} /></View>}
				{data.type==TransactionService.TYPE_REFERRAL_REWARD && <View sx={styles.iconLayer}><GradeOutlinedIcon color='primary' sx={{fontSize:30}}/></View>}
				{data.type==TransactionService.TYPE_CASH_REWARD && <View sx={styles.iconLayer}><GradeOutlinedIcon color='primary' sx={{fontSize:30}}/></View>}
				{data.type==TransactionService.TYPE_WITHDRAWL && <View sx={styles.iconLayerError}><SavingsOutlinedIcon color='error' sx={{fontSize:30}}/></View>}
				{data.type==TransactionService.TYPE_OTHER && <View sx={styles.iconLayer}><BlurCircularOutlinedIcon color='primary' sx={{fontSize:30}}/></View>}
				<View sx={styles.mainContent}>
					<Box component="span" sx={{fontSize:16, whiteSpace:'nowrap', textOverflow:'ellipsis', overflow:'hidden'}}>{data?.display_name}</Box>
					<Box component="span" sx={styles.labels}>{data.created_at}</Box>
				</View>
				<View sx={styles.infoContent}>
					{data.direction==TransactionService.DIRECTION_CREDIT && (<Box component="b" sx={{color: (data.status==TransactionService.STATUS_FAILED || data.status==TransactionService.STATUS_PENDING) ? 'black4' : 'success.main', textDecoration: (data.status==TransactionService.STATUS_FAILED ? 'line-through' : 'auto')}}>+{currentUser.currency_symbol}{currencyFormat(data.amount)}</Box>)}
					{data.direction==TransactionService.DIRECTION_DEBIT && (<Box component="b" sx={{color: (data.status==TransactionService.STATUS_FAILED || data.status==TransactionService.STATUS_PENDING) ? 'black4' : 'error.main', textDecoration: (data.status==TransactionService.STATUS_FAILED ? 'line-through' : 'auto')}}>-{currentUser.currency_symbol}{currencyFormat(data.amount)}</Box>)}

					<View sx={{gap:1, alignItems:'center', mt:1}}>
						{data.status==TransactionService.STATUS_PENDING && (<Box component="span" sx={{...styles.labels, mt:0, color: 'secondary.main'}}><AccessTimeRoundedIcon sx={{fontSize:16}} /></Box>)}
						{data.object?.unread_count>0 && (<Box sx={styles.redDot}>{data.object?.unread_count} <QuestionAnswerRoundedIcon sx={{ fontSize:12 }} /></Box>)}
					</View>
				</View>
			</View>
		</Box>
	);
}

export default TransactionItem;

