import {
	Box,
} from '@mui/material';

function View(props) {
	return (
		<Box
			{...props}
			sx={{
				display:'flex',
				...props.sx
			}}
		>{props.children}</Box>
	);
}

export default View;