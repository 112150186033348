import * as React from "react";
import {
  Box,
} from '@mui/material';
import {
  useNavigate,
} from "react-router-dom";
import View from '../ui/View';
import WhiteBox from '../layout/WhiteBox';
import SupportService from '../../services/SupportService';
import { PhotoProvider, PhotoView } from 'react-photo-view';

function SupportItem(props) {
  const {
    data,
    onClick=null,
    marginBottom=0,
    marginTop=0,
  } = props;

  const statusColor = React.useMemo(() => {
    if (data.status==SupportService.STATUS_PENDING) {
      return 'secondary.main';
    } else if (data.status==SupportService.STATUS_FINISHED) {
      return 'success.main';
    } else {
      return 'transparent';
    }
  }, [data])

  return (
    <Box onClick={onClick} sx={{marginTop, marginBottom}}>
      <WhiteBox 
        sx={{
          flexDirection: 'column',
          borderLeftWidth: 4,
          borderLeftColor: statusColor,
          borderLeftStyle: 'solid',
          borderRadius: 3,
        }}
      >
        <Box sx={{px:2, py:4,}}>
          <Box>{data.user_description}</Box>
          <Box sx={{mt:2, color: 'black6', fontSize:12,}}>{data.created_at}</Box>
        </Box>
        {data.status==SupportService.STATUS_FINISHED && (
          <Box sx={{backgroundColor: '#e1f3ce', px:2, py:3, borderRadius:'0 0 10px 10px',}}>
            <Box>{data.response_description}</Box>
            {data.images?.length && (
              <PhotoProvider>
                <View sx={{my:2}}>
                  {data.images.map(_image => (
                    <PhotoView src={_image.public_url}>
                      <Box
                        component="img"
                        src={_image.public_url}
                        sx={{
                          width: 100,
                          height: 100,
                          borderRadius: 2,
                          objectFit:'cover',
                        }}
                      />
                    </PhotoView>
                  ))}
                </View>
              </PhotoProvider>
            )}
          </Box>
        )}
      </WhiteBox>
    </Box>
  );
}

export default SupportItem;