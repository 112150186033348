import * as React from "react";
import {
	Outlet,
} from "react-router-dom";
import {
	CssBaseline,
	Container,
	Box,
	Link,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2';
import Copyright from '../components/layout/Copyright';
import CheckIcon from '@mui/icons-material/Check';
import GlobalSnackbar from './GlobalSnackbar';
import constants from '../constants/constant';

function BasicLayout(props) {

	return (
		<>
			<CssBaseline />
			<Box
				sx={{
					backgroundColor: 'black1',
					height: 330,
					backgroundImage: 'url(/image/background.svg)',
					backgroundPosition:'center',
					backgroundSize:'cover',
				}}
			>
				<Box sx={{textAlign:{xs:'center', md:'inherit'}, pt:2}}>
					<Link href={constants.HOMESITE_DOMAIN}>
						<Box
							component="img"
							sx={{
								width: 70,
								mx:3,
							}}
							src="/image/logo-light.svg"
						/>
					</Link>
				</Box>
			</Box>
			<Container sx={{mt:-15, px:0}}>
				<Outlet />
			</Container>
			<Copyright />
			<GlobalSnackbar />
		</>
	)
}

export default BasicLayout