import * as React from "react";
import {
	Box,
	Button,
	Container,
} from '@mui/material';
import {
	useNavigate,
	useSearchParams,
} from "react-router-dom";
import View from '../../components/ui/View';
import UserService from '../../services/UserService';
import CouponItem from '../../components/account/CouponItem';
import Loading from '../../components/ui/Loading';
import HeaderPrimary from '../../components/layout/HeaderPrimary';
import EmptyList from '../../components/layout/EmptyList';
import { useMotionValueEvent, useScroll } from "framer-motion";

function CouponPage(props) {
	const [coupons, setCoupons] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const currentPage = React.useRef(1);
	const lockBottomReach = React.useRef(true);
	const hasMore = React.useRef(true);
	const { scrollYProgress } = useScroll();

	React.useEffect(() => {
		loadData();
		return () => {}
	}, [])

	const loadData = async() => {
		try {
			setLoading(true)
			const response = await UserService.getUserCoupons(currentPage.current);
			let finalList = [...coupons, ...response.data];
			setCoupons(finalList);
			lockBottomReach.current = false;
			hasMore.current = !!response.next_page_url;
		} catch(e) {
		}
		setLoading(false);
	}

	const loadMore = async() => {
		if (lockBottomReach.current || !hasMore.current) {
			return;
		}
		lockBottomReach.current = true;
		currentPage.current++;
		loadData();
	}

	useMotionValueEvent(scrollYProgress, "change", (latest) => {
		if (latest>0.8) {
			loadMore();
		}
	})

	return (
		<Container>
			<HeaderPrimary label="Coupons" type="dark" />
			{coupons.length>0 && coupons.map(item => (
				<CouponItem data={item} key={`coupon_item_${item.id}`} />
			))}
			{!loading && coupons.length==0 && <EmptyList />}
			{loading && <Loading />}
		</Container>
	)
}

export default CouponPage