import * as React from "react";
import {
	Box,
	Button,
	Container,
	Chip,
} from '@mui/material';
import {
	Link,
	useNavigate,
	useSearchParams,
} from "react-router-dom";
import { useAuth, reloadUser } from '../../providers/AuthProvider';
import View from '../../components/ui/View';
import Grid from '@mui/material/Unstable_Grid2'
import {
	currencyFormat,
	getCurrencySymbolByCurrency,
} from '../../services/UtilService';
import Level from '../../components/account/Level';
import BoldTitle from '../../components/ui/BoldTitle';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { Swiper, SwiperSlide } from 'swiper/react';
import TransactionItem from '../../components/transaction/TransactionItem';
import EmptyList from '../../components/layout/EmptyList';
import GradientButton from '../../components/ui/GradientButton';
import Loading from '../../components/ui/Loading';
import TransactionService from '../../services/TransactionService';
import HeaderPrimary from '../../components/layout/HeaderPrimary';
import { useMotionValueEvent, useScroll } from "framer-motion";
import WhiteBox from '../../components/layout/WhiteBox';
import { Helmet } from 'react-helmet';

function TransactionListPage(props) {

	const [searchParams, setSearchParams] = useSearchParams();
	const [data, setData] = React.useState([]);
	const [loading, setLoading] = React.useState(false);
	const [inited, setInited] = React.useState(false);
	const [refreshing, setRefreshing] = React.useState(false);
	const currentPage = React.useRef(1);
	const hasMore = React.useRef(true);
	const [filter, setFilter] = React.useState(null);
	const { scrollYProgress } = useScroll();
	const lockBottomReach = React.useRef(true);

	React.useEffect(() => {
		setInited(true);
	}, []);

	React.useEffect(() => {
		if (searchParams.get('reload')) {
			reloadData();
		}
	}, [searchParams]);

	React.useEffect(() => {
		if (!inited) {
			reloadData();
		}
	}, [inited])

	React.useEffect(() => {
		if (inited) {
			reloadData();	
		}
	}, [filter]);

	useMotionValueEvent(scrollYProgress, "change", (latest) => {
		if (latest>0.8) {
			loadMore();
		}
	})

	const initData = async() => {
		setInited(true);
		currentPage.current = 1;
		hasMore.current = true;
		loadData(true)
	};

	const loadData = async(reload=false) => {
		if (reload) {
			setData([])
		}
		setLoading(true);
		try {
			const response = await TransactionService.getTransactions(currentPage.current, filter);
			let finalList = [];
			if (reload) {
				finalList = response.data;
			} else {
				finalList = [...data, ...response.data];	
			}
			setData(finalList);
			setLoading(false);
			setRefreshing(false);
			lockBottomReach.current = false;
			hasMore.current = !!response.next_page_url;
		} catch(e) {
		}
	}

	const loadMore = async() => {
		if (lockBottomReach.current || !hasMore.current) {
			return;
		}
		lockBottomReach.current = true;
		currentPage.current++;
		loadData();
	}
	
	const changeFilter = (name) => {
		setFilter(name)
	}

	const reloadData = async() => {
		setRefreshing(true);
		hasMore.current = true;
		currentPage.current = 1;
		loadData(true);
	}


	return (
		<>
			<Helmet>
				<title>My Transactions</title>
			</Helmet>
			<HeaderPrimary
				label="Transactions"
				type="dark"
				showBackButton={false}
			/>
			<Box sx={s.mobileFilterSection}>
				<Chip
					sx={s.mobileFilterSection.filterItem} 
					color={!filter ? 'primary' : 'default'} 
					onClick={() => changeFilter()} 
					label="All"
				/>
				<Chip
					sx={s.mobileFilterSection.filterItem} 
					color={filter=='giftcard' ? 'primary' : 'default'} 
					onClick={() => changeFilter('giftcard')}
					label="GiftCards"
				/>
				<Chip
					sx={s.mobileFilterSection.filterItem} 
					color={filter=='withdrawal' ? 'primary' : 'default'} 
					onClick={() => changeFilter('withdrawal')}
					label="Withdrawals"
				/>
				<Chip
					sx={s.mobileFilterSection.filterItem} 
					color={filter=='other' ? 'primary' : 'default'} 
					onClick={() => changeFilter('other')}
					label="Others"
				/>
			</Box>
			<Container sx={{pb:4}}>
				<WhiteBox>
					{data.length==0 && !loading && (
						<EmptyList sx={{mt: 0}}/>
					)}
					{data.length>0 && data.map(i => (
						<TransactionItem
							data={i}
							showBackgroundColor
							key={`transaction_${i.id}`}
						/>
					))}
					{loading && data.length==0 && (<Loading />)}
				</WhiteBox>
			</Container>
		</>
	)
}

export default TransactionListPage

const s = {
	header: {
		backgroundColor: 'transparent',
	},
	mobileFilterSection: {
		my: 3,
		display: 'flex',
		justifyContent:{xs:'flex-start', md:'center'},
		overflow: 'scroll',
		filterItem: {
			borderWidth: 0,
			ml: 1.5,
		}
	},
};