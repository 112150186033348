import * as React from "react";
import {
	Box,
	Button,
} from '@mui/material';
import {
	useNavigate,
} from "react-router-dom";

function DetailOther(props) {
	const {
		data,
	} = props;

	const styles = {

	}

	return (
		<></>
	);
}

export default DetailOther;

