import * as React from "react";
import {
	Box,
	Collapse,
	Button,
} from '@mui/material';
import {
	useNavigate,
} from "react-router-dom";
import View from '../ui/View';
import TransactionService from '../../services/TransactionService';
import { currencyFormat, getCurrencySymbolByCurrency } from '../../services/UtilService';
import WhiteBox from '../layout/WhiteBox';
import SuccessResult from './SuccessResult';
import PendingResult from './PendingResult';
import RejectResult from './RejectResult';
import { useAuth } from '../../providers/AuthProvider';
import OrderDataColumn from './OrderDataColumn';
import WithdrawService from '../../services/WithdrawService';
import ImageService from '../../services/ImageService';
import SupportCaseFormModal from '../trade/SupportCaseFormModal';
import SupportMessageItem from '../trade/SupportMessageItem';
import SupportService from '../../services/SupportService';
import FlowStepper from './FlowStepper';
import SupportMessageFixedInput from '../trade/SupportMessageFixedInput';

function DetailWithdraw(props) {
	const {
		withdrawal,
	} = props;
	const { user: currentUser } = useAuth();
	const [showSupportForm, setShowSupportForm] = React.useState(false)
	const [supportMessages, setSupportMessages] = React.useState([]);

	const styles = {
		cardInfo: {
			px: 4,
			py: 2,
			image: {
				width: 80,
				height: 50,
				borderRadius: 3,
				mr: 2,
			},
			content: {
				flex: 1,
			},
			cardTitle: {
				fontSize:16,
			},
			cardSubTitle: {
				mt: 1,
				flex: 1,
				fontSize:12,
			},
		},
	};

	React.useEffect(() => {
		loadSupportMessages();
	}, []);

	const loadSupportMessages = async() => {
		try {
			const response = await SupportService.getWithdrawalSupportMessages(withdrawal.id);
			setSupportMessages(response);
		} catch(e) {
		}
	}

	const handleSupportSuccess = () => {
		loadSupportMessages();
		setShowSupportForm(false)
	}

	const stepperIcons = React.useMemo(() => {
		return [
			{icon: '/image/step_pending.svg', name:'Processing'},
			{icon: withdrawal.status==WithdrawService.STATUS_FAILED ? '/image/step_fail.svg' : '/image/step_success.svg', name: withdrawal.status==WithdrawService.STATUS_FAILED ? 'Rejected' : 'Success'},
		];
	}, [withdrawal])

	const currentStepIndex = React.useMemo(() => {
		let current = 1;
		switch (withdrawal.status) {
			case WithdrawService.STATUS_COMPLETED:
			case WithdrawService.STATUS_FAILED:
			case WithdrawService.STATUS_CANCELLED:
				current = 2;
				break;
		}
		return current;
	}, [withdrawal])

	return (
		<>
			<WhiteBox sx={{my: 3}}>
				<FlowStepper
					steps={stepperIcons}
					currentStep={currentStepIndex}
				/>
				{withdrawal.status==WithdrawService.STATUS_FAILED && <RejectResult
					reason={withdrawal.failed_reason}
					image={withdrawal.images.length>0 ? ImageService.getVisitUrl(withdrawal.images.public_url, currentUser) : null}
				/>}

				<OrderDataColumn title="ID" value={withdrawal.display_id} />
				<OrderDataColumn title="Amount" value={`${getCurrencySymbolByCurrency(withdrawal.currency)}${currencyFormat(withdrawal.amount)}`} />
				{withdrawal.amount_bonus>0 && (
					<OrderDataColumn title="Bonus" value={`${getCurrencySymbolByCurrency(withdrawal.currency)}${currencyFormat(withdrawal.amount_bonus)}`} />
				)}
				<OrderDataColumn title="Bank" value={`${withdrawal.bank.bank.name}`} />
				<OrderDataColumn title="Account Number" value={withdrawal.bank.bank_account_number_thumb} />
				<OrderDataColumn title="Account Name" value={withdrawal.bank.bank_account_name} />
			</WhiteBox>

			{supportMessages.map(i => <SupportMessageItem data={i} key={`support_item_${i.id}`} />)}
			<SupportMessageFixedInput
				onSubmit={handleSupportSuccess}
				data={withdrawal}
				type="withdrawal"
			/>

		</>
	);
}

export default DetailWithdraw;

