import * as React from "react";
import {
	Box,
} from '@mui/material';
import View from '../ui/View';
import TouchableOpacity from '../ui/TouchableOpacity';

function MenuItem({label, icon, onClick, extra=null}) {
	return (
		<TouchableOpacity onClick={onClick} sx={s.menuItem}>
			<Box 
				component={icon}
				sx={s.menuItem.icon}
			/>
			<View sx={s.menuItem.content}>
				<Box>{label}</Box>
				<Box>{extra}</Box>
			</View>
		</TouchableOpacity>
	);
}

export default MenuItem;

const s = {
	menuItem: {
		display:'flex',
		flexDirection: 'row',
		alignItems: 'center',
		height: 85,
		icon: {
			mx: 2,
			color: '#4f5564',
			fontSize:30,
		},
		content: {
			height: 85,
			alignItems: 'center',
			borderBottomColor: '#f3f4f5',
			borderBottomStyle: 'solid',
			borderBottomWidth: 1,
			flex: 1,
		},
	},
}