import * as React from "react";
import {
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";

let MessageContext = React.createContext(null);

function Provider(props) {
  let [messageOpen, setMessageOpen] = React.useState(false);
  let [messageContent, setMessageContent] = React.useState(null);
  let [successOpen, setSuccessOpen] = React.useState(false);
  let [successContent, setSuccessContent] = React.useState(null);

  let show = (message) => {
    setMessageContent(message)
    setMessageOpen(true)
    setTimeout(() => {
      setMessageOpen(false)
    }, 2500)
  }

  let showSuccess = (message='Successful operation') => {
    setSuccessContent(message)
    setSuccessOpen(true)
    setTimeout(() => {
      setSuccessOpen(false)
    }, 1500)
  }
  
  let inject = { 
    messageOpen: messageOpen, 
    messageContent: messageContent,
    successOpen,
    successContent,
    show, 
    showSuccess,
  }
  return <MessageContext.Provider value={inject}>{props.children}</MessageContext.Provider>;
}

export const useMessage = () => {
  return React.useContext(MessageContext)
}

export default {
  Provider,
  useMessage,
}