import Request from './Request';

export default {
    TYPE_ORDER_CARD: 10,
    TYPE_ORDER_CARD_FEEDBACK: 11,
    TYPE_WITHDRAWL: 20,
    TYPE_SUPPORT: 30,
    TYPE_SUPPORT_FEEDBACK: 31,
    getVisitUrl(imagePublicUrl, currentUser) {
        return `${imagePublicUrl}?_access_token=${currentUser.auth?.access_token}`;
    },
}