import { createTheme } from '@mui/material/styles';

export default createTheme({
	palette: {
		primary: {
			light: '#ffd9b6',
			main: '#ef6b22',
			dark: '#e06d0c',
			contrastText: '#fff',
		},
		secondary: {
			light: '#CBBCD6',
			main: '#5277E1',
			dark: '#E8EEFF',
			contrastText: '#fff',
		},
		success: {
			light: '#CFEBB0',
			main: '#00B661',
			dark: '#EDFCDC',
			contrastText: '#fff',
		},
		info: {
			light: '#bec3d2',
			main: '#70778b',
			dark: '#3d4353',
			contrastText: '#fff',
		},
		error: {
			light: '#ffe5e8',
			main: '#ED5564',
			dark: '#FFE5E8',
		},
		black1: '#f5f7fa',
		black2: '#F0F2F7',
		black3: '#E2E6EE',
		black4: '#CAD1DD',
		black5: '#9DA6B9',
		black6: '#70778B',
		black7: '#4F5564',
		black8: '#20232B',
		white6: 'rgba(255,255,255,0.66)',
		whiteTheme: {
			main: '#fff',
		},
	},
	typography: {
		fontFamily: [
			'Poppins',
			'Roboto',
		].join(','),
	},
	shape: {
		borderRadius: 3,
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				body: {
					backgroundColor:'#f5f7fa'
				}
			}
		},
		MuiTypography: {
			styleOverrides: {
				root: {
					color: '#4F5564',
				},
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					textTransform: "none",
					borderRadius: 9,
				},
				sizeLarge: {
					height: 50,
					fontSize: 18,
				},
				sizeMedium: {
					height: 40,
					fontSize: 14,
				},
				sizeSmall: {
					height: 30,
				},
			},
			defaultProps: {
				disableElevation: true
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					backgroundColor: "rgba(255,255,255,1)",
					borderRadius:9,
				},
				notchedOutline: {
					borderColor:'transparent',
				}
			}
		},
		MuiMenuItem: {
			styleOverrides: {
				gutters: {
					paddingTop: 10,
					paddingLeft: 20,
					paddingBottom: 10,
					paddingRight: 20,
				}
			}
		},
		MuiBreadcrumbs: {
			styleOverrides: {
				root: {
					color: 'rgba(0,0,0,0.9)'
				}
			}
		},
		MuiSkeleton: {
			defaultProps: {
				animation: 'wave',
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					boxShadow: '0 3px 5px rgba(0,0,0,0.13)'
				}
			}
		},
		MuiAccordion: {
			styleOverrides: {
				root: {
					borderRadius: 5,
					marginBottom: 12,
					':before': {
						height: 0,
					}	
				}
			}
		},
	}
})