import * as React from "react";
import {
  Box,
  Container,
  Typography,
  Modal,
  IconButton,
  InputAdornment,
} from '@mui/material';
import {
  useNavigate,
} from "react-router-dom";

import WhiteBox from '../layout/WhiteBox';
import TaskService from '../../services/TaskService';

function RewardModel({show, type, message, onClose}) {
	return (
		<Modal
      open={show}
      onClose={onClose}
    >
      <WhiteBox
        style={{
          py:5,
          width: 270,
          alignItems:'center',
          flexDirection: 'column',
          display:'flex',
          gap: 4,
        }}
      >
        {type==TaskService.REWARD_TYPE_EXP && <Box component='img' src="/image/star.svg" sx={{width:80, height:80}} />}
        {type==TaskService.REWARD_TYPE_LOTTERY && <Box component='img' src="/image/gift.svg" sx={{width:80, height:80}} />}
        {type==TaskService.REWARD_TYPE_COUPON && <Box component='img' src='/image/coupon.svg' sx={{width:80, height:80}} />}
        <Box sx={{fontWeight:'bold'}}>{message}</Box>
      </WhiteBox>
    </Modal>
	);
}

export default RewardModel;