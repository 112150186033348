import React from 'react';
import {
	Box,
	Container,
	Typography,
} from '@mui/material';

export default function WhiteBox({children, sx}) {
	return (
		<Box 
			sx={{
				backgroundColor: 'white',
				boxShadow: '0 2px 2px rgba(32,35,43,0.05)',
				borderRadius: 3,
				...sx,
			}}
		>
			{children}
		</Box>
	);
}