import * as React from "react";
import {
	Box,
	Button,
	Typography,
	Container,
	IconButton,
} from '@mui/material';
import View from '../../components/ui/View';
import {
	useNavigate,
} from "react-router-dom";
import { useAuth } from '../../providers/AuthProvider';
import { useMessage } from '../../providers/MessageProvider';
import { setClipBoard } from '../../services/UtilService';
import WhiteBox from '../../components/layout/WhiteBox';
import Level from '../../components/account/Level';
import MenuItem from '../../components/layout/MenuItem';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import CardGiftcardRoundedIcon from '@mui/icons-material/CardGiftcardRounded';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import TouchableOpacity from '../../components/ui/TouchableOpacity';
import constants from '../../constants/constant';

function ProfileHeader() {
	const { user:currentUser } = useAuth();
	const navigate = useNavigate();

	return <>
		<Box
			sx={{
				backgroundColor: 'black1',
				height: {xs:330, md:420},
				mt: {xs:0, md:'-90px'},
				backgroundImage: 'url(/image/background.svg)',
				backgroundPosition:'center',
				backgroundSize:'cover',
			}}
		>
		</Box>
		<Container sx={{mt:-40, px:0}}>
			<View sx={s.baseInfo}>
				<View sx={s.baseInfo.avatarLayer}>
					<Box
						component="img"
						src={currentUser.avatar}
						sx={s.baseInfo.avatar}
					/>
				</View>
				<View sx={s.baseInfo.content}>
					<View sx={s.baseInfo.line1}>
						<Box
							sx={{
								color: 'white',
								maxWidth: 200,
								fontWeight:'bold',
								fontSize:24,
								whiteSpace:'nowrap',
								textOverflow:'ellipsis',
								overflow:'hidden',
							}}
						>{currentUser?.username}</Box>
						<Level level={currentUser?.level} sx={{marginLeft: 10}} onClick={() => navigate('/level')} />
					</View>
					<View sx={{width: 150, marginTop: 1, flexDirection:'column'}}>
						<View onClick={() => navigate('/level')} sx={s.baseInfo.rateContent} >
							<Box sx={{color: 'rgba(255,255,255,0.8)'}}>Growth Rates</Box>
							<Box sx={{color: 'rgba(255,255,255,0.8)'}}>{parseInt(currentUser.level_process_rate*100)}%</Box>
						</View>
						<View sx={s.baseInfo.rateLine} >
							<Box
								sx={{
									borderRadius: 2,
									height: 2,
									backgroundImage: 'linear-gradient(to right, rgba(32,35,43,0.6), rgba(32,35,43,1))',
									width: `${parseInt(currentUser.level_process_rate*100)}%`,
								}}
							/>
						</View>
					</View>
				</View>
			</View>
			<ReferralSection />
		</Container>
	</>
}

function ReferralSection() {
	const { user: currentUser } = useAuth();
	const message = useMessage();
	const navigate = useNavigate();

	const copyValue = (value) => {
		setClipBoard(value);
		message.showSuccess('Copied');
	}

	const toReferralPage = () => {
		const url = `${constants.BACKEND_DOMAIN}/app/referral?_access_token=${currentUser.auth.access_token}`;
		const title = 'Referral';
		navigate('/web', {
			state: {
				url, 
				title
			}
		});
	}

	return (
		<View sx={s.referralSection}>
			<Box sx={{color: 'rgba(255,255,255,0.7)', fontSize:12,}}>Referral</Box>
			<View sx={s.referralSection.content}>
				<View sx={{flex:1}}>
					<Box sx={{color: 'white'}}>
						Share 
					</Box>
					<Box
						onClick={() => copyValue(currentUser.invite_code)}
						sx={{
							cursor:'pointer',
							textDecorationLine: 'underline', 
							color: 'white',
							mx: 1,
							fontWeight:'bold',
						}}
					>
						{currentUser.invite_code}
					</Box>
					<Box sx={{color: 'white'}}>
						to invite friends
					</Box>
				</View>
				<TouchableOpacity sx={{alignItems:'center', justifyContent:'flex-end', display:'flex'}} onClick={toReferralPage} >
					<Box sx={{color:'white'}} >{currentUser.referees_count>0 ? `+${currentUser.referees_count}` :  ''}</Box>
					<ChevronRightRoundedIcon sx={s.referralSection.moreIcon} />
				</TouchableOpacity>
			</View>
		</View>
	);
}

function UserProfilePage({route, navigation}) {
	const { signOut, reloadUser } = useAuth();
	const navigate = useNavigate();

	React.useEffect(() => {
		reloadUser();
		return () => {}
	}, [])

	const shareApp = () => {
		navigator.share({title: 'Exchange Naira with PayPal, Gift Cards and Crypto - Fast and Reliable! Just at CardEaze', url: 'https://www.cardeaze.com'});
	}

	return (
		<>
			<ProfileHeader />
			<Container
				sx={{
					marginTop: -2,
					borderRadius: 15,
					paddingTop: 4,
				}}
			>
				<WhiteBox sx={{flexDirection: 'column'}}>
					<MenuItem label="Coupons" icon={CardGiftcardRoundedIcon} onClick={() => navigate('/coupons')} />
					<MenuItem label="Security" icon={AdminPanelSettingsOutlinedIcon} onClick={() => navigate('/security/signin')} />
				</WhiteBox>
				<Button
					onClick={signOut}
					fullWidth
					sx={{marginTop:4,}}
				>Sign Out</Button>
			</Container>
		</>
	);
}

export default UserProfilePage;

const s = {
	headerSection: {

	},
	baseInfo: {
		px: 2,
		py: 7,
		alignItems: 'flex-start',
		avatarLayer: {
			backgroundColor: '#d9d9d9',
			borderRadius: 50,
			borderWidth: 3,
			borderColor: 'white',
			borderStyle: 'solid',
		},
		avatar: {
			borderRadius: 32,
			width: 80,
			height: 80,
		},
		content: {
			flex: 1,
			marginLeft: 3,
			flexDirection:'column',
		},
		line1: {
			alignItems: 'center',
			marginTop: 1,
		},
		rateContent: {
			justifyContent: 'space-between',
			fontSize:12,
		},
		rateLine: {
			marginTop: 1,
			backgroundColor: '#d9d9d9',
			borderRadius: 2,
			height: 2,
		},
	},
	referralSection: {
		px: 2,
		paddingTop: 3,
		backgroundColor: 'rgba(0,0,0,0.3)',
		borderRadius:'15px 15px 0 0',
		flexDirection:'column',
		content: {
			alignItems: 'center',
			height: 80,
			justifyContent: 'space-between',
		},
		moreIcon: {
			color: 'rgba(255,255,255,0.6)',
			fontSize:30,
		},
	},
};