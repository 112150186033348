import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import GA4 from 'react-ga4';
import { BrowserRouter } from "react-router-dom";
import { createRoot } from 'react-dom/client';

// 初始化GA统计
GA4.initialize([
	{trackingId:'G-ESX8LM9PLX'},
	{trackingId:'AW-11055585461'},
]);
GA4.send('pageview');

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<BrowserRouter><App /></BrowserRouter>);