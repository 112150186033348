import * as React from "react";
import {
	Container,
	Box,
	Button,
	IconButton,
} from '@mui/material';
import {
	Link,
	useNavigate,
} from "react-router-dom";
import GradientButton from '../ui/GradientButton';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { useGlobalData } from '../../providers/GlobalProvider';
import _ from 'lodash';

function AppHeader(props) {
	const navigate = useNavigate();
	const { showTabbar, settings } = useGlobalData();
	const [chatSetting, setChatSetting] = React.useState({
		enable: false,
		link: '',
	})
	const css = {
		navItem: {
			transition:'all 0.2s',
			':hover': {
				opacity:0.5,
			}
		},
		navAppItem: {
			px:1.5,
			py:1,
			img: {
				width: 24,
				height: 24,
			}
		}
	};

	React.useEffect(() => {
		if (!_.isEmpty(settings)) {
			const chat_number = _.find(settings.CHAT, i => i.name=='NUMBER')?.value;
			setChatSetting({
				enable: _.find(settings.CHAT, i => i.name=='ENABLE').value=='1',
				link: chat_number ? `https://wa.me/${chat_number}?text=Hi` : '',
			})
		}
	}, [settings])

	const toSellPage = () => {
		navigate('/gift-cards');
	}

	return (
		<>
			<Box
				sx={{
					display: {xs:'none', md:'block'},
					height: 90,
					backgroundImage:'linear-gradient(to bottom, rgba(255,255,255,0.7), rgba(255,255,255,0.4))',
					position:'fixed',
					zIndex:2,
					top:0,
					width:'100%',
					backdropFilter:'blur(8px)',
					borderBottom:'1px solid rgba(255,255,255,0.1)',
					boxShadow:'0 5px 10px rgba(0,0,0,0.05)',
				}}
			>
				<Container>
					<Box sx={{display:'flex', alignItems:'center', height:90}}>
						<Box component="img" src="/image/logo.png" sx={{height:60,}}/>
						<Box sx={{flex:1, gap:4, display:'flex', justifyContent:'center'}}>
							<Box to="/" component={Link} sx={css.navItem}>Home</Box>
							<Box to="/transactions" component={Link} sx={css.navItem}>Transactions</Box>
							<Box to="/profile" component={Link} sx={css.navItem}>User</Box>
						</Box>
						<Button onClick={toSellPage}>
							<Box component='img' src='/image/sell_button.png' sx={{width:70}} />
						</Button>
					</Box>
				</Container>
			</Box>
			<Box sx={{height:90, display: {xs:'none', md:'block'},}} />
			{showTabbar && (
				<Box
					sx={{
						display: {xs:'flex', md:'none'},
						height: 60,
						borderRadius: '15px 15px 0 0',
						backgroundColor:'white',
						position:'fixed',
						zIndex:2,
						bottom:0,
						width:'100%',
						boxShadow:'0 -2px 3px rgba(0,0,0,0.05)',
					}}
				>
					<Box
						sx={{
							display:'flex',
							justifyContent:'space-around',
							alignItems:'center',
							flex:1,
						}}
					>
						<Box to="/" component={Link} sx={css.navAppItem}>
							<Box component="img" src="/image/logo.png" sx={{height:30,}}/>
						</Box>
						<Box to="/transactions" component={Link} sx={css.navAppItem}>
							<Box component="img" src="/image/tab_order.svg" />
						</Box>
						<Button onClick={toSellPage} sx={{mt:-2.5}}>
							<Box component='img' src='/image/sell_button.png' sx={{width:65}} />
						</Button>
						{chatSetting.enable && (
							<Box component={'a'} target="_blank" href={chatSetting.link} sx={css.navAppItem}>
								<Box component="img" src="/image/tab_customer.svg" />
							</Box>
						)}
						<Box to="/profile" component={Link} sx={css.navAppItem}>
							<Box component="img" src="/image/tab_my.svg" />
						</Box>
					</Box>
				</Box>
			)}
			
		</>
	);
}

export default AppHeader;