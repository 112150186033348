import * as React from "react";
import {
	Box,
} from '@mui/material';
import { Player } from '@lottiefiles/react-lottie-player';

function Loading(props) {
	return (
		<Player
			autoplay={true}
			loop={true}
			controls={false}
			src={`/lottie/loading.json`}
			style={{ height: '150px' }}
		/>
	);
}

export default Loading;
