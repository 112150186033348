import * as React from "react";
import {
	Box,
	Button,
	Container,
	Avatar,
	Skeleton,
	TextField,
	InputAdornment,
} from '@mui/material';
import {
	Link,
	useNavigate,
	useParams,
} from "react-router-dom";
import { useAuth, reloadUser } from '../../providers/AuthProvider';
import { useMessage } from '../../providers/MessageProvider';
import UserService from '../../services/UserService';
import View from '../../components/ui/View';
import WhiteBox from '../../components/layout/WhiteBox';
import EmptyList from '../../components/layout/EmptyList';
import SelectGiftCardCategoryModal from '../../components/transaction/SelectGiftCardCategoryModal';
import CardService from '../../services/CardService';
import HeaderPrimary from '../../components/layout/HeaderPrimary';
import ManageSearchRoundedIcon from '@mui/icons-material/ManageSearchRounded';
import Grid from '@mui/material/Unstable_Grid2'
import { useGlobalData } from '../../providers/GlobalProvider';

const s = {
	searchBar: {
		pt: 2,
		flexDirection:'column',
		desc: {
			color: '#FFFFFF',
			opacity: 0.8,
			textAlign: 'center',
		},
		searchInput: {
			mt: 4,
			'& .MuiOutlinedInput-root': {
				backgroundColor: 'rgba(255,255,255,0.3)',
			},
			'& .MuiOutlinedInput-input': {
				color:'white',
			}
		},
	},
	cardSection: {
		mt:3,
		justifyContent:'center'
	},
	cardItemLayer: {
	},
	cardItem: {
		borderRadius: 3,
		backgroundColor: '#ffffff',
		boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
		cursor:'pointer',
		image: {
			height: 100,
			borderRadius: '10px 10px 0 0',
			backgroundColor: '#CAD1DD',
			width:'100%',
			objectFit:'cover',
		},
		textLayer: {
			alignItems: 'center',
			justifyContent: 'center',
			flexDirection: 'row',
			height: 50,
		},
		text: {
			px: 1,
			fontSize:13,
			textAlign:'center',
		},
	},
}

function ItemSkeleton(props) {
	return (
		<Grid xs={6} md={3}>
			<View sx={{...s.cardItem, flexDirection:'column'}}>
				<Skeleton sx={{...s.cardItem.image, transform: 'scale(1, 1)',}}/>
				<View sx={s.cardItem.textLayer}>
					<Skeleton width={100}/>
				</View>
			</View>
		</Grid>
	)
}

function SelectGiftCardPage(props) {
	const message = useMessage();
	const navigate = useNavigate();
	const [loading, setLoading] = React.useState(false);
	const loaded = React.useRef(false);
	const [searchKeyword, setSearchKeyword] = React.useState('');
	const [selectedCard, setSelectedCard] = React.useState();
	const [categoryVisible, setCategoryVisible] = React.useState(false);
	const { cardId } = useParams();
	const { cards, setCards } = useGlobalData();

	React.useEffect(() => {
		loadAllCards();
		return () => {}
	}, [])

	React.useEffect(() => {
		if (cardId && cards.length>0) {
			const findCard = cards.find(i => i.id==cardId);
			if (findCard) {
				chooseCard(chooseCard)
			}
		}
	}, [cardId, cards]);

	const loadAllCards = async() => {
		if (loaded.current) {
			return;
		}
		if (cards?.length==0) {
			setLoading(true);	
		}
		try {
			const response = await CardService.getAllCards();
			setCards(response);
			loaded.current = true;
		} catch(e) {
			message.show(e?.message);
		}
		setLoading(false);
	}

	const handleCardCategorySelect = (e) => {
		setCategoryVisible(false)
		navigate(`/gift-cards/submit?cardId=${e.card_id}&cardCategoryId=${e.id}`)
	}

	const chooseCard = (i) => {
		setSelectedCard(i);
		setCategoryVisible(true);
	}

	const filteredCards = React.useMemo(() => {
		return cards.filter(i => i.name.toLowerCase().indexOf(searchKeyword.toLowerCase())>-1);
	}, [searchKeyword, cards]);

	return (
		<>
			<Box
				sx={{
					backgroundColor: 'black1',
					height: {xs:'210px', md:'300px'},
					mt: {xs:0, md:'-90px'},
					backgroundImage: 'url(/image/background.svg)',
					backgroundPosition:'center',
					backgroundSize:'cover',
				}}
			>
			</Box>
			<Container sx={{mt:'-210px', px:0}}>
				<Box sx={{background:'black1', px:2}}>
					<HeaderPrimary label="Start Trading" />
					<View sx={s.searchBar}>
						<Box sx={s.searchBar.desc}>What do you want to sell?</Box>
						<TextField
							mode="contained"
							onChange={t => setSearchKeyword(t.target.value.trim())}
							placeholder="Search"
							InputProps={{
			        	startAdornment: (
			            <InputAdornment position="start">
			              <ManageSearchRoundedIcon sx={{color:'rgba(255,255,255,0.8)'}} />
			            </InputAdornment>
			          ),
			        }}
							sx={s.searchBar.searchInput}
							size="small"
						/>
					</View>

					<View sx={s.cardSection} >
						<Grid sx={{minWidth:'100%'}} container spacing={{xs:2, md:3}} >
							{loading && (
								<>
									<ItemSkeleton />
									<ItemSkeleton />
									<ItemSkeleton />
									<ItemSkeleton />
									<ItemSkeleton />
									<ItemSkeleton />
									<ItemSkeleton />
									<ItemSkeleton />
								</>
							)}

							{filteredCards.map(i => (
								<Grid xs={6} md={3}  key={`card_item_${i.id}`}>
									<Box
										sx={s.cardItem}
										onClick={() => chooseCard(i)}
									>
										<Box component="img" src={i.image} sx={s.cardItem.image} />
										<View sx={s.cardItem.textLayer}>
											<Box
												component="span"
												sx={s.cardItem.text}
											>{i.name}</Box>
										</View>
									</Box>
								</Grid>
							))}
						</Grid>
						{!loading && filteredCards.length==0 && (<EmptyList />)}
					</View>
					<SelectGiftCardCategoryModal
						open={categoryVisible}
						card={selectedCard}
						onClose={() => setCategoryVisible(false)}
						onSelect={handleCardCategorySelect}
					/>
				</Box>
			</Container>

		</>
	);
}

export default SelectGiftCardPage