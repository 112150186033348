import {
  Navigate,
} from "react-router-dom";

function NotFound(props) {
	return (
		<Navigate to="/" replace={true}/>
	)
}

export default NotFound