import * as React from "react";
import {
	Box,
	Button,
	Container,
	Avatar,
	Skeleton,
} from '@mui/material';
import {
	Link,
	useNavigate,
} from "react-router-dom";
import View from '../../components/ui/View';
import { useAuth, reloadUser } from '../../providers/AuthProvider';
import UserService from '../../services/UserService';
import TaskService from '../../services/TaskService';
import {
	currencyFormat,
	getCurrencySymbolByCurrency,
} from '../../services/UtilService';
import Level from '../../components/account/Level';
import WhiteBox from '../../components/layout/WhiteBox';
import BoldTitle from '../../components/ui/BoldTitle';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { Swiper, SwiperSlide } from 'swiper/react';
import TransactionItem from '../../components/transaction/TransactionItem';
import EmptyList from '../../components/layout/EmptyList';
import GradientButton from '../../components/ui/GradientButton';
import { useGlobalData } from '../../providers/GlobalProvider';
import 'swiper/css';

function HeaderSection({}) {
	const {
		user: currentUser,
	} = useAuth();
	const navigate = useNavigate();

	const toWithdrawPage = () => {
		navigate('/withdrawal/submit');
	}

	const toSellPage = () => {
		navigate('/gift-cards');
	}

	const toProfilePage = () => {
		navigate('/profile');
	}

	return (
		<Box>
			<View sx={s.headerSection}>
				<View sx={s.baseInfo}>
					<View sx={s.baseInfo.wallet}>
						<View sx={s.baseInfo.wallet.amount}>
							<Box component="b" sx={{color: 'white', mb:0.6, mr: 0.7, fontSize:22}} >{currentUser.currency_symbol}</Box>
							<Box component="b" sx={{color: 'white', fontSize:34, textShadow:'0 3px 5px rgba(0,0,0,0.1)'}} >{currencyFormat(currentUser?.wallet?.amount_balance)}</Box>
						</View>
						<Box component="span" sx={s.baseInfo.wallet.title} >Available Balance</Box>
					</View>
					<View sx={s.baseInfo.user}>
						<Box sx={s.baseInfo.user.avatarLayer} onClick={toProfilePage}>
							<Avatar
								src={currentUser.avatar}
								sx={s.baseInfo.user.avatar}
							/>
							<Level
								level={currentUser?.level}
								size="small"
								sx={{
									position: 'absolute',
									bottom: -12,
									left: 10,
								}}
								onClick={() => {
									navigate('/level');
								}}
							/>
						</Box>
					</View>
				</View>
				<View sx={s.headerSection.actions}>
					<View sx={s.headerSection.actions.item} onClick={toWithdrawPage}>
						<View sx={s.headerSection.actions.walletIcon}>
							<View component="img" src="/image/tab_wallet.svg" sx={{width:35, height:35}} />
						</View>
						<View component="span" sx={{...s.headerSection.actions.title, fontSize:14}}>Withdraw</View>
					</View>
					<View sx={{...s.headerSection.actions.item, flex: 1}} onClick={toSellPage}>
						<View sx={{...s.headerSection.actions.walletIcon, width: '100%'}}>
							<View component="img" src="/image/sellButton.png" sx={s.headerSection.sellButton} />
						</View>
						<View component="b" sx={{...s.headerSection.actions.title}} >SELL GIFTCARD</View>
					</View>
				</View>
			</View>
		</Box>
	);
}


function BannerSection({topRates=[], tasks=[], banners=[]}) {
	const navigate = useNavigate();
	const { user: currentUser } = useAuth();

	const toTopRatePage = () => {
		navigate('/top-rates');
	}
	const toTaskPage = () => {
		navigate('/tasks');
	}
	const toWebView = (url, title) => {
		url = url.replace('_TOKEN_', currentUser.auth.access_token);
		navigate('/web', {
			state: {
				url,
				title
			}
		});
	}

	return (
		<WhiteBox sx={s.bannerSection}>
			<View sx={s.bannerColumn}>
				<Box onClick={toTopRatePage} sx={[s.bannerColumnItem, s.bannerColumnItemFirst]}>
					<View sx={s.bannerColumnItem.title}>
						<View sx={{flex:1}}><Box component="img" src='/image/home_rate_icon.png' sx={{height:'30px'}} /></View>
						<ChevronRightRoundedIcon  />
					</View>
					{topRates.length>0 && topRates.slice(0, 3).map(i => (
						<View sx={s.topRateItem} key={`rate-item-${i.id}`}>
							<Box component="img" src={i.card_category.country?.image} sx={s.topRateItem.image}  />
							<Box component="span" sx={s.topRateItem.content} >{i?.display_name}</Box>
							<Box component='b' sx={s.topRateItem.rate}>{getCurrencySymbolByCurrency(i.currency)}{i.rate}</Box>
						</View>
					))}
					{topRates.length==0 && (<>
						<Skeleton sx={{mt:1.5}} />
						<Skeleton sx={{mt:1.5}} />
						<Skeleton sx={{mt:1.5}} />
					</>)}
				</Box>
				<Box onClick={toTaskPage} sx={s.bannerColumnItem}>
					<View sx={s.bannerColumnItem.title}>
						<View sx={{flex:1}}><Box component="img" src='/image/home_task_icon.png' sx={{height:'30px'}} /></View>
						{tasks.filter(i => i.status==TaskService.STATUS_AVAILABLE_CHECK).length>0 && (
							<View sx={{width:8, height:8, borderRadius:8, backgroundColor:'red'}} />
						)}
						<ChevronRightRoundedIcon  />
					</View>
					{tasks.slice(0, 3).map(i => (
						<View sx={s.topRateItem} key={`task_item_${i.id}`}>
							<Box component="span" sx={{...s.topRateItem.content, ml: 0}}>{i.name}</Box>
						</View>
					))}
					{tasks.length==0 && (<>
						<Skeleton sx={{mt:1.5}} />
						<Skeleton sx={{mt:1.5}} />
						<Skeleton sx={{mt:1.5}} />
					</>)}
				</Box>
			</View>
			<Box sx={s.bannerImageLayer}>
				<Swiper>
					{banners.map(i => (
						<SwiperSlide
							component={SwiperSlide}
							onClick={() => toWebView(i.url, i.title)}
							key={`banner_item_${i.id}`}
						>
							<Box sx={{textAlign:'center', cursor:'pointer'}}>
								<Box component="img" src={i.image} sx={s.bannerImage} />
							</Box>
						</SwiperSlide>
					))}
				</Swiper>
			</Box>
		</WhiteBox>
	);
}

function LastTransationSection({data=[]}) {
	const navigate = useNavigate();

	const toTransationsPage = () => {
		navigate('/transactions');
	}

	const toSellPage = () => {
		navigate('/gift-cards');
	}

	return (<>
		<BoldTitle>Recent Activities</BoldTitle>
		<WhiteBox>
		{data.length>0 && (
			<>
				{data.map(i => <TransactionItem key={`home_transaction_${i.id}`} data={i}/>)}
				<Button
					onClick={toTransationsPage}
					fullWidth
					sx={{borderRadius: 0}}
				>MORE</Button>
			</>
		)}
		{data.length==0 && (
			<Box sx={{textAlign:'center'}}>
				<EmptyList />
				<GradientButton
					onClick={toSellPage}
					size="large"
					sx={{
						mb: 5,
					}}
				>SELL GIFTCARD</GradientButton>
			</Box>
		)}
		</WhiteBox>
	</>);
}


function DashboardPage(props) {
	const auth = useAuth();
	const { reloadUser } = useAuth();
	const [ homeData, setHomeData ] = React.useState({});
	const { loadSettings } = useGlobalData();

	React.useEffect(() => {
		loadData();
		reloadUser();
		loadSettings();
		return () => {}
	}, [])

	const loadData = async() => {
		try {
			const response = await UserService.getHomeData()
			setHomeData(response);
		} catch(e) {
		}
	}

	return (
		<>
			<Box
				sx={{
					backgroundColor: 'black1',
					height: {xs:330, md:420},
					mt: {xs:0, md:'-90px'},
					backgroundImage: 'url(/image/background.svg)',
					backgroundPosition:'center',
					backgroundSize:'cover',
				}}
			>
			</Box>
			<Container sx={{mt:-40, px:0}}>
				<HeaderSection />
				<Box sx={{background:'black1', p:2}}>
					<BannerSection
						topRates={homeData.top_rates}
						tasks={homeData.tasks}
						banners={homeData.banners}
					/>
					<LastTransationSection data={homeData.last_transaction} />
				</Box>
			</Container>
		</>
	)
}

const s = {
	headerSection: {
		pt: {xs:3, md:6},
		px: 2,
		flexDirection:{xs:'column', md:'row'},
		actions: {
			pt: 5,
			gap: 3,
			item: {
				alignItems: 'center',
				cursor:'pointer',
				flexDirection:'column',
				transition:'all 0.3s',
				':hover': {
					opacity:0.8,
				}
			},
			walletIcon: {
				height: 70,
				width: 70,
				borderRadius: 4,
				alignItems: 'center',
				justifyContent: 'center',
				backgroundColor: '#FFFFFF',
				boxShadow:'0 5px 10px rgba(0,0,0,0.08)',
				overflow: 'hidden',
			},
			title: {
				color: '#FFF',
				lineHeight: '45px',
				textShadow:'0 3px 6px rgba(0,0,0,0.1)',
			},
		},
		sellButton:{
			height: 70,
		},
	},
	baseInfo: {
		flex:1,
		gap:2,
		justifyContent: {xs:'space-between', md:'flex-end'},
		flexDirection: {xs:'row', md:'row-reverse'},
		alignItems:'center',
		wallet: {
			flexDirection:'column',
			amount: {
				display:'flex',
				alignItems: 'flex-end',
			},
			title: {
				mt: 0.5,
				color: 'white6',
			},
		},
		user: {
			alignItems:'flex-start',
			avatarLayer: {
				border:'2px solid #fff',
				borderRadius: 10,
				position: 'relative',
			},
			avatar: {
				backgroundColor: 'rgba(255,255,255,1)',
				height: 50,
				width: 50,
			}
		},
	},
	bannerSection: {
		display:'flex',
		height: 330,
		mt:3,
	},
	bannerColumn: {
		display:'flex',
		flex: 1,
		flexDirection: 'column',
		overflow:'hidden',
	},
	bannerColumnItemFirst: {
		borderBottom:'1px solid #E6E9ED'
	},
	bannerColumnItem: {
		flex: 1,
		pl:1.5,
		pr:1,
		borderRight: '1px solid #E6E9ED',
		transition:'all 0.3s',
		cursor:'pointer',
		':hover': {
			backgroundColor:'black1',
		},
		title: {
			alignItems: 'center',
			mb:1.5,
			mt:2,
		},
		titleIconLayer: {
			width: 24,
			height: 24,
			backgroundColor: 'rgba(238,111,1,0.1)',
			borderRadius: 4,
			justifyContent: 'center',
			alignItems: 'center',
		},
		titleContent: {
			flex: 1,
			fontSize:18,
		},
	},
	topRateItem: {
		alignItems: 'center',
		mt: 1.5,
		image: {
			width: 13,
			height: 13,
		},
		content: {
			flex: 1,
			mx: 1,
			textOverflow:'ellipsis',
			overflow:'hidden',
			whiteSpace:'nowrap',
			fontSize:13,
		},
		rate: {
			fontSize:14,
		},
	},
	bannerImageLayer: {
		borderRadius: '0 10px 10px 0',
		overflow: 'hidden',
		flex:1,
		display: 'flex',
		alignItems:'center',
	},
	bannerImage: {
		height: '100%',
		maxWidth:'100%',
		maxHeight:330
	},
	fixedHeader: {
		position: 'absolute',
		zIndex: 3,
		top: 0,
		left: 0,
		right: 0,
		justifyContent: 'space-between',
		alignItems: 'center',
		px: 3,
		pb: 2,
		wallet: {
			amount: {
				alignItems: 'center',
			},
		},
		user: {
			avatarLayer: {
				border: '1px solid #fff',
				borderRadius: 8,
			},
			avatar: {
				backgroundColor: 'rgba(255,255,255,1)',
				height: 40,
				width: 40,
			}
		},
	},
}

export default DashboardPage