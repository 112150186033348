import * as React from "react";
import {
  Box,
} from '@mui/material';
import {
  useNavigate,
} from "react-router-dom";
import View from '../../components/ui/View';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';

function Keyboard(props) {
  const [currentNumber, setCurrentNumber] = React.useState(0);
  const {
    onChange,
    max=null,
    min=0,
  } = props;

  React.useEffect(() => {
    onChange(currentNumber)
  }, [currentNumber]);

  const addNumber = (e) => {
    if (max!==null) {
      setCurrentNumber(Math.min(parseInt(currentNumber*10 + e), max))
    } else {
      setCurrentNumber(parseInt(currentNumber*10 + e))  
    }
  }

  const backNumber = (e) => {
    setCurrentNumber(parseInt(currentNumber/10))
  }

  return (
    <View sx={s.layer} >
      <Box sx={s.number} onClick={() => addNumber(1)}>
        <View sx={s.numberItem}>
          <Box sx={s.headlineSmall}>1</Box>
        </View>
      </Box>
      <Box sx={s.number} onClick={() => addNumber(2)}>
        <View sx={s.numberItem}>
          <Box sx={s.headlineSmall}>2</Box>
        </View>
      </Box>
      <Box sx={s.number} onClick={() => addNumber(3)}>
        <View sx={s.numberItem}>
          <Box sx={s.headlineSmall}>3</Box>
        </View>
      </Box>
      <Box sx={s.number} onClick={() => addNumber(4)}>
        <View sx={s.numberItem}>
          <Box sx={s.headlineSmall}>4</Box>
        </View>
      </Box>
      <Box sx={s.number} onClick={() => addNumber(5)}>
        <View sx={s.numberItem}>
          <Box sx={s.headlineSmall}>5</Box>
        </View>
      </Box>
      <Box sx={s.number} onClick={() => addNumber(6)}>
        <View sx={s.numberItem}>
          <Box sx={s.headlineSmall}>6</Box>
        </View>
      </Box>
      <Box sx={s.number} onClick={() => addNumber(7)}>
        <View sx={s.numberItem}>
          <Box sx={s.headlineSmall}>7</Box>
        </View>
      </Box>
      <Box sx={s.number} onClick={() => addNumber(8)}>
        <View sx={s.numberItem}>
          <Box sx={s.headlineSmall}>8</Box>
        </View>
      </Box>
      <Box sx={s.number} onClick={() => addNumber(9)}>
        <View sx={s.numberItem}>
          <Box sx={s.headlineSmall}>9</Box>
        </View>
      </Box>
      <Box sx={s.number} onClick={() => addNumber(max)}>
        <View sx={s.numberItem}>
          <Box sx={s.headlineSmall}>MAX</Box>
        </View>
      </Box>
      <Box sx={s.number} onClick={() => addNumber(0)}>
        <View sx={s.numberItem}>
          <Box sx={s.headlineSmall}>0</Box>
        </View>
      </Box>
      <Box sx={s.number} onClick={() => backNumber()}>
        <View sx={s.numberItem}>
          <KeyboardArrowLeftRoundedIcon sx={{fontSize:30, color:'black8'}}/>
        </View>
      </Box>
    </View>
  );
}

export default Keyboard;

const s = {
  layer: {
    mx:'auto',
    flexWrap: 'wrap',
    py: 2,
    justifyContent:'space-between',
    width: {xs:'100%', md:'60%'},
  },
  number: {
    flexBasis: '30%',
    height: 55,
    marginBottom: 2,
    cursor:'pointer',
    transaction:'all 0.3s',
    ':hover': {

    },
    ':active': {
      opacity:0.4
    },
  },
  numberItem: {
    height: 55,
    backgroundColor: 'white',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 3,
    boxShadow: '0 2px 6px #aab2bc38',
  },
  numberText: {
  },
  headlineSmall:{
    fontWeight:'bold',
    fontSize:28,
  },
};