import Request from './Request';

const storage_user_key = 'current_user'

export default {
	async signIn(username, password) {
		return Request.post('/api/tokens', {
			username,
			password,
		});
	},
	async signOut() {
		localStorage.removeItem(storage_user_key)
		localStorage.removeItem('access_token')
	},
	async signUp(username, email, password, invite_code=null, country='NIGERIA') {
		return Request.post('/api/users', {
			username,
			email,
			password,
			invite_code,
			country,
			source: 'WEB',
		});
	},
	async checkVerificationCode(email, code) {
		return Request.post('/api/user/verify', {
			email,
			verification_code: code,
		});
	},
	setLocalUser(user) {
		localStorage.setItem(storage_user_key, JSON.stringify(user));
		if (user.auth) {
			localStorage.setItem('access_token', user.auth.access_token);	
		}
	},
	getLocalUser() {
		let user = localStorage.getItem(storage_user_key)
		return JSON.parse(user)
	},
	async sendResetPasswordCode(identity) {
		return Request.post('/api/password/find', {
			identity,
		});
	},
	async resetPasswordByVerificationCode(identity, verification_code, new_password) {
		return Request.post('/api/password/find/verify', {
			identity,
			verification_code,
			new_password,
		});
	},
	async getCurrentUser() {
		return Request.get('/api/current-user');
	},
	async getWallet() {
		return Request.get('/api/current-user/wallet');
	},
	async updateProfile() {
		// BTODO
	},
	async getHomeData() {
		return Request.get('/api/home', {});
	},
	async getUserCoupons(page=1) {
		return Request.get('/api/user/coupons', {page});
	},
	async getAllAvailableCoupons() {
		return Request.get('/api/user/coupons', {active:1, all:1});
	},
	async createOrChangePinPassword(pin, old_pin) {
		return Request.post('/api/user/pin-password', {pin, old_pin});
	},
	async checkPinPassword(pin) {
		return Request.get('/api/user/pin-password/verify', {pin});
	},
	async changePassword(old_password, new_password) {
		return Request.post('/api/user/password', {old_password, new_password});
	},
}