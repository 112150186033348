import * as React from "react";
import {
	Box,
	Container,
	Typography,
} from '@mui/material';
import { motion } from "framer-motion";

export default function AuthLayout({title, children}) {
	return (
		<Box
			sx={{
				maxWidth:{xs:'100%', md:'60%'},
				ml:{xs:'0%', md:'20%'},
			}}
		>
			<Container>
				<Typography variant="h4" sx={{color:'white', fontWeight:'bold',}}>{title}</Typography>
			</Container>
			<Box 
				component={motion.div}
				initial={{ opacity: 0, y:50}}
				whileInView={{ opacity: 1, y: 0}}
				transition={{duration: 0.4}}
				viewport={{ once: true }}
				sx={{
					backgroundColor: '#f5f7fa',
					borderRadius:3,
					mt:3,
					py:3,
					px:{xs:2, md:3},
				}}
			>
				{children}
			</Box>
		</Box>
	);
}