import * as React from "react";
import {
	Box,
	Container,
	Typography,
	Modal,
	IconButton,
	TextField,
	InputAdornment,
} from '@mui/material';
import {
	useNavigate,
} from "react-router-dom";
import { useMessage } from '../../providers/MessageProvider';
import Loading from '../ui/Loading';
import EmptyList from '../layout/EmptyList';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ManageSearchRoundedIcon from '@mui/icons-material/ManageSearchRounded';
import View from '../ui/View';
import WhiteBox from '../layout/WhiteBox';
import BankService from '../../services/BankService';

function BankSelectionModal(props) {
	const {
		onClose,
		onSelect,
		open,
	} = props;
	const [searchKeyword, setSearchKeyword] = React.useState('');
	const [banks, setBanks] = React.useState([]);
	const [loading, setLoading] = React.useState(false);

	React.useEffect(() => {
		if (open) {
			loadBanks()
		} else {
		}
	}, [open]);

	const loadBanks = async() => {
		try {
			setLoading(true)
			const response = await BankService.getBanks();
			setBanks(response);
		} catch(e) {
		}
		setLoading(false)
	}

	const filterBanks = React.useMemo(() => {
		if (banks) {
			if (searchKeyword) {
				return banks.filter(i => i.name.toLowerCase().indexOf(searchKeyword.toLowerCase())>-1);
			} else {
				return banks;
			}
		} else {
			return [];
		}
	}, [banks, searchKeyword]);

	return (
		<Modal
			sx={styles.modalLayer}
			open={open}
			onClose={onClose}
		>
			<View sx={styles.modalContent}>
				<View sx={styles.header}>
					<Box sx={{color:'white', flex:1, fontSize:18}} >Select Bank</Box>
					<IconButton
							onClick={onClose}
							sx={{fontSize:34}}
						><CloseRoundedIcon /></IconButton>
				</View>
				<Box sx={styles.banksLayer}>
					<WhiteBox>
						{loading && <Loading />}
						{!loading && filterBanks.map(i => (
							<Box key={`bank_item_${i.id}`} onClick={() => onSelect(i)} >
								<View sx={styles.categoryItem}>
									<Box component="img" src={i.image} sx={styles.categoryItem.image}  />
									<Box sx={{flex: 1}}>{i.name}</Box>
								</View>
							</Box>
						))}
					</WhiteBox>
				</Box>
			</View>
		</Modal>
	);
}

export default BankSelectionModal;

const styles = {
	modalLayer: {
	},
	modalContent: {
		display:'flex',
		position:'absolute',
		bottom:0,
		left:{xs:0, md:'50%'},
		right:{xs:0, md:'inherit'},
		backgroundColor: '#f5f7fa',
		borderRadius:'12px 12px 0 0',
		flexDirection:'column',
		width:{xs:'100%', md:800},
		transform:{xs:'translateX(0)', md:'translateX(-50%) !important'},
		outline:'none',
		height: '80%',
	},
	header: {
		borderRadius: '10px 10px 0 0',
		p: 2,
		backgroundColor: 'primary.main',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	searchInput: {
		backgroundColor: 'rgba(255,255,255,0.25)'
	},
	banksLayer: {
		overflow:'scroll',
	},
	categoryItem: {
		alignItems: 'center',
		py: 3,
		px: 2,
		borderBottomColor: '#f3f4f5',
		borderBottomStyle: 'solid',
		borderBottomWidth: 1,
		image: {
			width: 30,
			height: 30,
			borderRadius: 4,
			marginRight: 2,
		}
	},
};