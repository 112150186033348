import _ from 'lodash';

export const currencyFormat = (number) => {
	return new Intl.NumberFormat().format(number);
}

export const getCurrencySymbolByCurrency = (currency) => {
	if (currency=='NAIRA') {
		return '₦';
	} else if(currency=='CEDI') {
		return '₵';
	} else {
		return '';
	}
}

export const parseUrl = (url) => {
	let result = null;
	const a = _.split(url, '://');
	if (a.length>0) {
		const b = _.split(a[1], '?');
		if (b.length>0) {
			const route = b[0];
			const params = b[1] ? JSON.parse('{"' + decodeURI(b[1]).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}') : {};
			result = {
				routeName: route,
				params: params
			}
		}
	}
	console.log('res', result)
	return result;
}

export const setClipBoard = (content) => {
	const dummy = document.createElement( 'textarea');
	dummy.style.position    = 'absolute';
	dummy.style.left        = '-9999px';
	dummy.style.top         ='-9999px';
	document.body.appendChild(dummy);
	dummy.value             = content;
	dummy.select();
	document.execCommand('copy');
	document.body.removeChild(dummy);
	return true;
}