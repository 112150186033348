import Request from './Request';

export default {
	STATUS_PENDING: 1,
	STATUS_PROCESSING: 2,
	STATUS_COMPLETED: 3,
	STATUS_FAILED: 4,
	STATUS_CANCELLED: 5,
	async createWithdraw(user_bank_id, amount) {
		return Request.post('/api/withdrawals', {
			user_bank_id,
			amount,
		});
	},
	async getUserBanks() {
		return Request.get('/api/withdrawals/user-banks');
	},
	
	async createUserBank(bank_id, account_number, account_name='') {
		return Request.post('/api/withdrawals/user-banks', {
			bank_id,
			account_number,
			account_name,
		});
	},
	async getLastUsedBank() {
		return Request.get('/api/withdrawals/user-bank/last-used');
	},
	async getWithdrawalConfig() {
		return Request.get('/api/withdrawals/config');
	},
}