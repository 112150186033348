import Request from './Request';

export default {
  STATUS_PENDING: 0,
  STATUS_FINISHED: 2,
  async createSupportCase(type, object_id, reason, images=[]) {
    return Request.postFormData('/api/supports', { type, object_id, reason, images });
  },
  async getAllSupports(page=1) {
    return Request.get(`/api/supports`, {page});
  },
  async getOrderCardSupports(order_card_id) {
    return Request.get(`/api/supports/order-card/${order_card_id}`);
  },
  async getWithdrawalSupports(withdrawal_id) {
    return Request.get(`/api/supports/withdrawal/${withdrawal_id}`);
  },
  async createSupportMessage(type, object_id, message, image=null) {
    return Request.postFormData('/api/support-messages', {type, object_id, message, image});
  },
  async getOrderCardSupportMessages(order_card_id) {
    return Request.get(`/api/support-messages/order-card/${order_card_id}`);
  },
  async getWithdrawalSupportMessages(withdrawal_id) {
    return Request.get(`/api/support-messages/withdrawal/${withdrawal_id}`);
  },
}