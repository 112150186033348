import * as React from "react";
import {
	Box,
	Button,
	Container,
	Skeleton,
	CircularProgress,
} from '@mui/material';
import {
	Link,
	useNavigate,
} from "react-router-dom";
import View from '../../components/ui/View';
import HeaderPrimary from '../../components/layout/HeaderPrimary';
import { useAuth } from '../../providers/AuthProvider';
import { useMessage } from '../../providers/MessageProvider';
import SystemService from '../../services/SystemService';
import { getCurrencySymbolByCurrency } from '../../services/UtilService';
import WhiteBox from '../../components/layout/WhiteBox';
import TouchableOpacity from '../../components/ui/TouchableOpacity';
import TaskService from '../../services/TaskService';
import RewardModel from '../../components/quest/RewardModel';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

function TaskItem({data}) {
	const [task ,setTask] = React.useState(data);
	const message = useMessage();
	const [showReward, setShowReward] = React.useState(false);
	const [locking, setLocking] = React.useState(false);

	const checkTask = async() => {
		if (locking) {
			return;
		}
		try {
			setLocking(true);
			if (task.status==TaskService.STATUS_AVAILABLE_CHECK) {
				const response = await TaskService.checkUserTask(task.user_task.id)
				setShowReward(true);
				setTask({
					...task,
					status: TaskService.STATUS_DONE
				})
			}
		} catch(e) {
			message.show(e?.message)
		}
		setLocking(false);
	}

	return (
		<View sx={css.taskItem}>
			<RewardModel
				open={showReward} 
				message={task.description}
				type={task.reward_type}
				onClose={() => setShowReward(false)}
			/>
			<View sx={css.rateNames}>
				<Box >{task.name}</Box>
				<Box sx={{marginTop: 1, fontSize:12, color:'black5', overflow:'hidden', textOverflow:'ellipsis', whiteSpace:'nowrap'}}>{task.description}</Box>
			</View>
			<View sx={css.taskStatus}>
			{task.status==TaskService.STATUS_INACTIVE && (
				<View
					sx={{
						borderColor: 'black6',
						width: 34,
						height: 34,
						borderRadius: 6,
						borderWidth: 2,
						borderStyle: 'dotted',
					}}>
				</View>
			)}
			{task.status==TaskService.STATUS_AVAILABLE_CHECK && (
				<TouchableOpacity onClick={() => checkTask()}>
					<View sx={{backgroundColor: 'primary.main', borderRadius:30, p:1, px:2}}>
					{!locking && <Box sx={{color: 'white'}}>Check</Box>}
					{locking && <CircularProgress size={24} color='whiteTheme' />}
					</View>
				</TouchableOpacity>
			)}
			{task.status==TaskService.STATUS_DONE && (
				<View sx={{backgroundColor: 'success.main', borderRadius: 30, p: 1,}}>
					<CheckRoundedIcon sx={{fontSize:24, color:'white'}}/>
				</View>
			)}
			</View>
		</View>
	);
}

function TaskPage(props) {
	const [loading, setLoading] = React.useState(false);
	const [refreshing, setRefreshing] = React.useState(false);
	const [tasks, setTasks] = React.useState([]);

	React.useEffect(() => {
		loadTasks();
	}, [])

	const loadTasks = async() => {
		setLoading(true);
		try {
			const response = await TaskService.getUserTasks();
			setTasks(response);
		} catch(e) {
		}
		setLoading(false);
	}

	const reloadData = async() => {
		setRefreshing(true)
		await loadTasks();
		setRefreshing(false)
	}

	return (
		<Container>
			<HeaderPrimary label="Tasks" type="dark" />
			<WhiteBox sx={{my:3}}>
			{!loading && tasks.map(item => <TaskItem data={item} key={`task_item_${item.id}`} />)}
			{loading && (
				<View sx={{flex: 1, mx:2, flexDirection:'column'}}>
					<Skeleton sx={{width: "100%", py:1, mb:2}} />
					<Skeleton sx={{width: "100%", py:1, mb:2}} />
					<Skeleton sx={{width: "100%", py:1, mb:2}} />
					<Skeleton sx={{width: "100%", py:1, mb:2}} />
					<Skeleton sx={{width: "100%", py:1, mb:2}} />
					<Skeleton sx={{width: "100%", py:1, mb:2}} />
					<Skeleton sx={{width: "100%", py:1, mb:2}} />
					<Skeleton sx={{width: "100%", py:1, mb:2}} />
				</View>
			)}
			</WhiteBox>
		</Container>
	);
}

export default TaskPage;

const css = {
	taskItem: {
		alignItems: 'center',
		px: 2,
		height: 100,
		borderBottom: '1px solid #f3f4f5',
	},
	rateNames: {
		flex: 1,
		flexDirection:'column',
	},
	taskStatus: {

	},
};