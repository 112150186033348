import * as React from "react";
import {
	Box,
} from '@mui/material';
import View from '../ui/View';

function OrderDataColumn({title, value}) {
	return <View sx={{
		justifyContent: 'space-between',
		borderBottomColor: '#f3f4f5',
		borderBottomStyle: 'solid',
		borderBottomWidth: 1,
		px: 2,
		py: {xs:2, md:3},
		fontSize:14,
	}}>
		<Box sx={{color: 'black7'}}>{title}</Box>
		<Box sx={{textAlign: 'right'}}>{value}</Box>
	</View>
}

export default OrderDataColumn;