import * as React from "react";
import {
	Outlet,
} from "react-router-dom";
import {
	CssBaseline,
	Box,
} from '@mui/material';
import GlobalSnackbar from './GlobalSnackbar';
import AppHeader from '../components/layout/AppHeader';


function AppLayout(props) {

	return (
		<>
			<CssBaseline />
			<AppHeader />
			<Outlet />
			<GlobalSnackbar />
			<Box sx={{height:80}} />
		</>
	)
}

export default AppLayout