import constant from '../constants/constant';
import axios from 'axios';
import _ from 'lodash';

axios.interceptors.response.use(
	(response) => {
		return response
	},
	(err) => {
		if (!err?.response) {
			err['response'] = {
				data: {
					message: 'Oops, something went wrong'
				}
			}
		}
		return Promise.reject(err.response.data);	
	}
);

const makeRequestHeader = () => {
	const access_token = localStorage.getItem('access_token')
	let headers = {}
	if( access_token ) {
		headers['Authorization'] = 'Bearer '+access_token
	}
	return headers
}

const get = async (uri, params) => {
	const headers = makeRequestHeader()
	const response = await axios.get(constant.BACKEND_DOMAIN+uri, {headers, params})
	return response.data
}

const post = async (uri, data, params) => {
	const headers = makeRequestHeader()
	const response = await axios.post(constant.BACKEND_DOMAIN+uri, data, {headers, params})
	return response.data
}

const postFormData = async (uri, data, params) => {
	const headers = makeRequestHeader()
	headers['Content-Type'] = 'application/x-www-form-urlencoded'
	let bodyFormData = new FormData()
	for(let i in data) {
		if (_.isArray(data[i])) {
			for(let j in data[i]) {
				bodyFormData.append(`${i}[]`, data[i][j])
			}
		} else {
			bodyFormData.append(i, data[i])	
		}
	}
	const response = await axios.post(constant.BACKEND_DOMAIN+uri, bodyFormData, {headers, params})
	return response.data
}

export default {
	get,
	post,
	postFormData,
}