import * as React from "react";
import {
	Box,
	Button,
	Container,
	Skeleton,
} from '@mui/material';
import {
	Link,
	useNavigate,
	useSearchParams,
	useParams,
} from "react-router-dom";
import { useAuth, reloadUser } from '../../providers/AuthProvider';
import View from '../../components/ui/View';
import {
	currencyFormat,
	getCurrencySymbolByCurrency,
} from '../../services/UtilService';
import WhiteBox from '../../components/layout/WhiteBox';
import TransactionService from '../../services/TransactionService';
import HeaderPrimary from '../../components/layout/HeaderPrimary';
import { useMessage } from '../../providers/MessageProvider';
import { useGlobalData } from '../../providers/GlobalProvider';

import DetailOther from '../../components/transaction/DetailOther';
import DetailOrderCard from '../../components/transaction/DetailOrderCard';
import DetailWithdraw from '../../components/transaction/DetailWithdraw';
import DetailReward from '../../components/transaction/DetailReward';


function OrderDataColumn({title, value}) {
	return <View sx={styles.dataColumn}>
		<Box sx={{color: 'black7'}}>{title}</Box>
		<Box>{value}</Box>
	</View>
}

function OrderDataColumnSkeleton() {
	return <View sx={{...styles.dataColumn, py:2}}>
		<Skeleton sx={{width: 100}} />
		<Skeleton sx={{width: 150}} />
	</View>
}


function TransactionDetailPage(props) {
	const { id } = useParams()
	const message = useMessage();
	const navigate = useNavigate();
	const [transaction, setTransaction] = React.useState();
	const [refreshing, setRefreshing] = React.useState(false);
	const { setShowTabbar } = useGlobalData();
	const looper = React.useRef();

	React.useEffect(() => {
		loadTransaction();
		setShowTabbar(false);
		return () => {
			setShowTabbar(true);
			clearTimeout(looper.current)
		}
	}, [])

	const loadTransaction = async() => {
		try {
			const response = await TransactionService.getTransactionDetail(id);
			setTransaction(response);
			if (
				(response.type==TransactionService.TYPE_SELLCARD || response.type==TransactionService.TYPE_WITHDRAWL) 
				&& response.status!=TransactionService.STATUS_SUCCESS 
				&& response.status!=TransactionService.STATUS_FAILED 
			) {
				looper.current = setTimeout(() => {
					loadTransaction();
				}, 10000)	
			}
		} catch(e) {
			message.show(e.message);
			navigate(-1);
		}
		return true;
	}

	const onRefresh = async() => {
		setRefreshing(true)
		await loadTransaction()
		setRefreshing(false)
	}


	return (
		<Container sx={{pb:3}}>
			<HeaderPrimary label="Detail" type="dark" />
			{!transaction && (
				<WhiteBox sx={{my: 4}}>
					<OrderDataColumnSkeleton />
					<OrderDataColumnSkeleton />
					<OrderDataColumnSkeleton />
					<OrderDataColumnSkeleton />
					<OrderDataColumnSkeleton />
					<OrderDataColumnSkeleton />
				</WhiteBox>
			)}
			{transaction && transaction.type==TransactionService.TYPE_SELLCARD && <DetailOrderCard orderCard={transaction.object} />}
			{transaction && transaction.type==TransactionService.TYPE_WITHDRAWL && <DetailWithdraw withdrawal={transaction.object} />}
			{transaction && transaction.type==TransactionService.TYPE_REFERRAL_REWARD && <DetailReward data={transaction} />}
			{transaction && transaction.type==TransactionService.TYPE_CASH_REWARD && <DetailReward data={transaction} />}
			{transaction && transaction.type==TransactionService.TYPE_OTHER && <DetailOther data={transaction} />}
			
		</Container>
	)
}

export default TransactionDetailPage

const styles = {
	dataColumn: {
		justifyContent: 'space-between',
		borderBottomColor: '#f3f4f5',
		borderBottomStyle: 'solid',
		borderBottomWidth: 1,
		mx: 2,
		py: 4,
	},
};