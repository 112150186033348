import * as React from "react";
import {
	Box,
	Button,
	Typography,
	TextField,
	FormHelperText,
	InputAdornment,
	Container,
	Checkbox,
	IconButton,
} from '@mui/material';
import constants from '../../constants/constant';
import { useAuth } from '../../providers/AuthProvider';
import { useMessage } from '../../providers/MessageProvider';
import UserService from '../../services/UserService';
import AuthLayout from '../../components/layout/AuthLayout';
import {
	Link,
	useNavigate,
} from "react-router-dom";
import _ from 'lodash';
import GradientButton from '../../components/ui/GradientButton';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import PermIdentityRoundedIcon from '@mui/icons-material/PermIdentityRounded';
import LockOpenRoundedIcon from '@mui/icons-material/LockOpenRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PeopleOutlineRoundedIcon from '@mui/icons-material/PeopleOutlineRounded';
import SendToMobileOutlinedIcon from '@mui/icons-material/SendToMobileOutlined';
import DialpadRoundedIcon from '@mui/icons-material/DialpadRounded';
import GA4 from 'react-ga4';

const styles = {
	terms: {
		display:'flex',
		flexDirection: 'row',
		alignItems: 'center',
		mb: 3,
		mt: 2,
		content: {
			flexWrap: 'wrap',
			flex: 1,
			fontSize:12,
		},
	},
	countryImage: {
		width:90,
		height:50,
	},
	countrySelectItem: {
		textAlign:'center',
		padding:1.5,
		border:'3px solid #eee',
		backgroundColor:'white',
		borderRadius:4,
		opacity:0.6,
		flex:1,
		cursor:'pointer',
	},
	countrySelectActive: {
		borderColor:'#ef6b22',
		backgroundColor:'rgba(254,149,1,0.16)',
		opacity:1,
	},
};

function RegisterPage(props) {
	const auth = useAuth();
	const message = useMessage();
	const navigate = useNavigate();
	const [username, setUsername] = React.useState('');
	const [email, setEmail] = React.useState('');
	const [password, setPassword] = React.useState('');
	const [referralCode, setReferralCode] = React.useState('');
	const [verificationCode, setVerificationCode] = React.useState('');
	const [termChecked, setTermChecked] = React.useState(true);
	const [loading, setLoading] = React.useState(false);
	const [verifing, setVerifing] = React.useState(false);
	const [countDown, setCountDown] = React.useState(null);
	const [showPassword, setShowPassword] = React.useState(false);
	const [country, setCountry] = React.useState('NIGERIA');
	const [errors, setErrors] = React.useState({
		username: null,
		email: null,
		password: null,
		verificationCode: null,
	});
	const [step ,setStep] = React.useState(1);
	const timer = React.useRef();

	React.useEffect(() => {
		if (countDown>0) {
			timer.current = setTimeout(() => {
				setCountDown(countDown-1);
			}, 1000);
		} else {
			setCountDown(null);
			clearTimeout(timer.current);
		}
	}, [countDown]);

	const resetError = (key) => {
		setErrors({
			...errors,
			[key]: null
		})
	}

	const onSignUp = async() => {
		try {
			let errorBag = {};
			if (username.trim().length<5 || username.trim().length>30) {
				errorBag['username'] = 'Username must be between 5 and 30 characters';
			}
			if (!username) {
				errorBag['username'] = 'Username can not be empty';
			}
			if (!email) {
				errorBag['email'] = 'Email can not be empty';
			}
			if (password.trim().length<5 || password.trim().length>30) {
				errorBag['password'] = 'Password at least 5 characters';
			}
			if (!password) {
				errorBag['password'] = 'Password can not be empty';
			}
			if (!termChecked) {
				message.show('Please agree to the agreement');
				return false;
			}
			if (!_.isEmpty(errorBag)) {
				setErrors({
					...errors,
					...errorBag,
				})
				return;
			}
			setLoading(true);
			const _user = await UserService.signUp(username, email, password, referralCode, country)
			toStepTwo();
		} catch(e) {
			message.show(e?.message);
			setLoading(false);
		}
	}

	const toSignIn = () => {
		navigate('/signin')
	}

	const onVerify = async() => {
		try {
			setVerifing(true)
			const _user = await UserService.checkVerificationCode(email, verificationCode);
			auth.signIn(_user);

			// 发送GA注册事件
			GA4.set({
				user_data: {
					email: `${email}`
				}
			});
			GA4.event('sign_up');
			GA4.event('conversion', {'send_to': 'AW-11055585461/Z8arCJ7ckvgYELWx25cp'});

			navigate('/', {replace: true})
		} catch(e) {
			message.show(e?.message);
			setVerifing(false)
		}
	}

	const resendVerificationCode = async() => {
		try {
			await UserService.signUp(username, email, password, referralCode)
			setCountDown(60);
		} catch(e) {
			message.show(e?.message);
			setLoading(false);
		}
	}

	const toStepTwo = () => {
		setStep(2);
		setCountDown(60);
	}

	const backToStepOne = () => {
		setStep(1);
		setLoading(false);
		setCountDown(null);
	}

	const togglePasswordShow = () => {
		setShowPassword(!showPassword);
	}

	function verificationCodeRightComponent() {
		if (countDown>0) {
			return (<InputAdornment position="end"><span>{`${countDown}s`}</span></InputAdornment>);
		} else {
			return (<InputAdornment position="end"><IconButton onClick={resendVerificationCode}><SendToMobileOutlinedIcon /></IconButton></InputAdornment>);
		}
	}

	return (
		<AuthLayout title="Sign Up">
			{step==1 && (
				<>
					<TextField
						fullWidth
						onChange={t => {
							resetError('username');
							setUsername(t.target.value);
						}}
						placeholder="Username"
						InputProps={{
		        	startAdornment: (
		            <InputAdornment position="start">
		              <PermIdentityRoundedIcon sx={{color:'black7'}} />
		            </InputAdornment>
		          ),
		        }}
					/>
					{errors.username && <FormHelperText error>{errors.username}</FormHelperText>}
					<TextField
						sx={{mt:{xs:2, md:3}}}
						fullWidth
						onChange={t => {
							resetError('email');
							setEmail(t.target.value);
						}}
						placeholder="Email"
						InputProps={{
		        	startAdornment: (
		            <InputAdornment position="start">
		              <EmailOutlinedIcon sx={{color:'black7'}} />
		            </InputAdornment>
		          ),
		        }}
					/>
					{errors.email && <FormHelperText error>{errors.email}</FormHelperText>}
					<TextField
						sx={{mt:{xs:2, md:3}}}
						fullWidth
						onChange={t => {
							resetError('password');
							setPassword(t.target.value);
						}}
						placeholder="Password"
						type={!showPassword ? 'password' : 'text'}
						InputProps={{
		        	startAdornment: (
		            <InputAdornment position="start">
		              <LockOpenRoundedIcon sx={{color:'black7'}} />
		            </InputAdornment>
		          ),
		          endAdornment: (
		          	!showPassword 
		          		? (<InputAdornment sx={{cursor:'pointer'}} position="end"><VisibilityOffRoundedIcon onClick={togglePasswordShow} sx={{color:'black7'}} /></InputAdornment>)
		          		: (<InputAdornment sx={{cursor:'pointer'}} position="end"><VisibilityRoundedIcon onClick={togglePasswordShow} sx={{color:'black7'}} /></InputAdornment>)
		          ),
		        }}
					/>
					{errors.password && <FormHelperText error>{errors.password}</FormHelperText>}

					<TextField
						sx={{mt:{xs:2, md:3}}}
						fullWidth
						onChange={t => {
							setReferralCode(t.target.value.toUpperCase());
						}}
						placeholder="Referral Code (Optional)"
						InputProps={{
		        	startAdornment: (
		            <InputAdornment position="start">
		              <PeopleOutlineRoundedIcon sx={{color:'black7'}} />
		            </InputAdornment>
		          ),
		        }}
					/>

					<Box sx={styles.terms} >
						<Checkbox
							checked={termChecked}
							onClick={() => setTermChecked(!termChecked)}
						/>
						<Box sx={styles.terms.content} >Agree to Cardeaze <a href={`${constants.HOMESITE_DOMAIN}/terms`} target="_blank">Terms and conditions</a> & <a href={`${constants.HOMESITE_DOMAIN}/privacy`} target="_blank">Privacy Policy</a></Box>
					</Box>

					<GradientButton
						icon='arrow-right'
						onClick={onSignUp}
						loading={loading}
						sx={{mt:2}}
						size="large"
						fullWidth
						endIcon={<NavigateNextRoundedIcon />}
					>
						Next
					</GradientButton>
					
					<Button
						onClick={toSignIn}
						fullWidth
						size="large"
						sx={{mt:2, color:'black8'}}
					>
						<Box variant='bodyLarge'>Have an account?&nbsp;</Box> <Box variant='bodyLarge' sx={{fontWeight: 'bold'}}>Sign In</Box>
					</Button>
				</>
			)}
			
			{step==2 && (
				<>
					<Box sx={{textAlign: 'center', mt: 3, mb: 3}}>
						Verification code has been sent to <b>{email}</b>
					</Box>
					<TextField
						fullWidth
						sx={{mt:{xs:2, md:3}}}
						onChange={t => {
							resetError('verificationCode');
							setVerificationCode(t.target.value);
						}}
						placeholder="Verification Code"
						type="number"
						InputProps={{
		        	startAdornment: (
		            <InputAdornment position="start">
		              <DialpadRoundedIcon sx={{color:'black7'}} />
		            </InputAdornment>
		          ),
		          endAdornment: verificationCodeRightComponent()
		        }}
					/>
					{errors.verificationCode && <FormHelperText error>{errors.verificationCode}</FormHelperText>}
					<GradientButton
						icon='arrow-right'
						onClick={onVerify}
						loading={verifing}
						sx={{mt:2}}
						size="large"
						fullWidth
						endIcon={<NavigateNextRoundedIcon />}
					>
						Next
					</GradientButton>
					<Button
						size="large"
						fullWidth
						onClick={backToStepOne} 
						sx={{mt:2}}
					>
						<Box variant='bodyLarge'>Back</Box>
					</Button>
				</>
			)}
		</AuthLayout>
	)
}

export default RegisterPage