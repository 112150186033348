import Request from './Request';

export default {
	STATUS_PENDING: 10,
	STATUS_CHECKING: 11,
	STATUS_TRANSFERING: 12,
	STATUS_TRANSFER_CHECKING: 13,
	STATUS_COMPLETED: 20,
	STATUS_FAILED: 30,
	STATUS_CANCELLED: 40,
}