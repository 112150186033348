import {
	Box,
	Button,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

function GradientButton(props) {
	return (
		<LoadingButton
			variant="contained"
			{...props}
			sx={{
				...props.sx,
				backgroundImage: 'linear-gradient(to right, #EE581E, #F38B2B) !important',
				opacity: props?.disabled ? 0.7 : 1,
				transition:'all 0.3s',
			}}
		>{props.children}</LoadingButton>
	);
}

export default GradientButton;