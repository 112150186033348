import React, { useEffect, useState, useContext } from "react";
import {
  Routes,
  Route,
  Link,
  Outlet,
  useLocation,
  Navigate,
} from "react-router-dom";
import Auth from './providers/AuthProvider'
import GlobalMessage from './providers/MessageProvider'
import GlobalData from './providers/GlobalProvider'
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import 'react-photo-view/dist/react-photo-view.css';

import BasicLayout from './layouts/BasicLayout'
import AppLayout from './layouts/AppLayout'
import NotFound from './pages/NotFound'

import UserProfilePage from './pages/account/UserProfilePage';
import LoginPage from './pages/account/LoginPage';
import RegisterPage from './pages/account/RegisterPage';
import FindPasswordPage from './pages/account/FindPasswordPage';
import DashboardPage from './pages/home/DashboardPage'
import WebPage from './pages/home/WebPage';
import TopRatePage from './pages/home/TopRatePage';
import TaskPage from './pages/home/TaskPage';
import SubmitGiftCardPage from './pages/trade/SubmitGiftCardPage';
import TransactionListPage from './pages/trade/TransactionListPage';
import TransactionDetailPage from './pages/trade/TransactionDetailPage';
import WithdrawCreatePage from './pages/withdraw/WithdrawCreatePage';
import CouponPage from './pages/account/CouponPage';
import SupportPage from './pages/account/SupportPage';
import BankAccountsPage from './pages/account/BankAccountsPage';
import BankAccountCreatePage from './pages/account/BankAccountCreatePage';
import SelectGiftCardPage from './pages/trade/SelectGiftCardPage';
import SuccessPage from './pages/trade/SuccessPage';
import PinCheckPage from './pages/account/PinCheckPage';
import SecurityPage from './pages/account/SecurityPage';
import ChangeLoginPasswordPage from './pages/account/ChangeLoginPasswordPage';
import CouponSelectionPage from './pages/account/CouponSelectionPage';
import LevelPage from './pages/home/LevelPage';
import { Helmet } from 'react-helmet';

function AppRouter(props) {

  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return <Routes>
    <Route element={<AuthMiddleware />} >
      <Route element={<AppLayout />} >
        <Route path="/" element={<DashboardPage />} />
        <Route path="profile" element={<UserProfilePage />} />
        <Route path="web" element={<WebPage />} />
        <Route path="top-rates" element={<TopRatePage />} />
        <Route path="tasks" element={<TaskPage />} />
        <Route path="gift-cards" element={<SelectGiftCardPage />} />
        <Route path="gift-cards/submit" element={<SubmitGiftCardPage />} />
        <Route path="transactions" element={<TransactionListPage />} />
        <Route path="transactions/:id" element={<TransactionDetailPage />} />
        <Route path="withdrawal/submit" element={<WithdrawCreatePage />} />
        <Route path="coupons" element={<CouponPage />} />
        <Route path="coupons/choose" element={<CouponSelectionPage />} />
        <Route path="supports" element={<SupportPage />} />
        <Route path="bank-accounts" element={<BankAccountsPage />} />
        <Route path="bank-accounts/submit" element={<BankAccountCreatePage />} />
        <Route path="success" element={<SuccessPage />} />
        <Route path="pin/check" element={<PinCheckPage />} />
        <Route path="security" element={<SecurityPage />} />
        <Route path="security/signin" element={<ChangeLoginPasswordPage />} />
        <Route path="level" element={<LevelPage />} />
      </Route>
    </Route>
    <Route element={<BasicLayout />} >
      <Route path="/signin" element={<LoginPage />} />
      <Route path="/signup" element={<RegisterPage />} />
      <Route path="/reset-password" element={<FindPasswordPage />} />
    </Route>
    <Route path="*" element={<NotFound />}/>
  </Routes>
}

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Auth.Provider>
        <GlobalData.Provider>
        <GlobalMessage.Provider>
          <Helmet>
            <title>CardEaze | Sell Gift Cards, Paypal Funds for Instant Cash Online</title>
          </Helmet>
          <AppRouter />
        </GlobalMessage.Provider>
        </GlobalData.Provider>
        </Auth.Provider>
      </ThemeProvider>
    </>
  )
}

function AuthMiddleware() {
  let auth = Auth.useAuth();
  let location = useLocation();
  if( !auth.user ) {
    return <Navigate to="/signin" state={{ from: location }} />;
  }
  return <Outlet />
}

export default App