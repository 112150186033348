import Request from './Request';

export default {
	async getTopRates() {
		return Request.get('/api/system/rates/rank');
	},
	async getCountries() {
		return Request.get('/api/system/countries');
	},
	async getAppConfigs() {
		return Request.get('/api/system/configs');
	},
	async getSettings() {
		return Request.get('/api/settings');
	},
}