import * as React from "react";
import {
	Box,
	Button,
	Container,
	Skeleton,
	CircularProgress,
} from '@mui/material';
import {
	Link,
	useNavigate,
	useSearchParams,
	useLocation,
} from "react-router-dom";
import View from '../../components/ui/View';
import HeaderPrimary from '../../components/layout/HeaderPrimary';

function WebPage({route, navigation}) {
	const location = useLocation();

	return (
		<Box 
			sx={{
				display:'flex',
				flexDirection:'column',
				position:'fixed',
				height:'100%',
				width:'100%',
				top:0,
			}}
		>
			<Container>
				<HeaderPrimary label={location.state?.title} type="dark" />

			</Container>
			<Box
				component="iframe"
				src={location.state?.url}
				sx={{
					mt:3,
					border:0,
					width:'100%',
					maxWidth:'1100px',
					flex:1,
					pb:{xs:'70px', md:0},
					mx:'auto',
				}}
			/>
		</Box>
	);
}

export default WebPage;