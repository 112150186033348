import React from 'react';
import {
	Box,
	Typography,
} from '@mui/material';
import { motion } from "framer-motion";

export default function Copyright(props) {
	return (
		<Box sx={{color:'black4', textAlign:'center', fontSize:12, mt:5, mb:3}}>
			© 2023 CardEaze. All Rights Reserved.
		</Box>
	);
}