import Request from './Request';

export default {
	TYPE_SELLCARD: 10,
	TYPE_REFERRAL_REWARD: 13,
	TYPE_CASH_REWARD: 14,
	TYPE_WITHDRAWL: 20,
	TYPE_OTHER: 90,
	
	DIRECTION_CREDIT: 'CR',
	DIRECTION_DEBIT: 'DE',

	STATUS_PENDING: 0,
	STATUS_AVAILABLE: 1,
	STATUS_FAILED: 2,

	async getTransactions(page, type=null) {
		return Request.get('/api/transactions', {page, type});
	},
	async getTransactionDetail(id) {
		return Request.get(`/api/transactions/${id}`, {});
	},
}