import * as React from "react";
import {
	Box,
} from '@mui/material';

function TouchableOpacity(props) {
	return <Box 
		{...props}
		sx={{
			cursor:'pointer',
			transition:'all 0.2s',
			":hover":{
				opacity:0.6,
			},
			...props.sx,
		}}
	>{props.children}</Box>
}

export default TouchableOpacity;