import * as React from "react";
import {
	Box,
} from '@mui/material';
import {
	useNavigate,
} from "react-router-dom";
import View from '../ui/View';

function BankAccountItem({data, onClick=() => {}}) {
	return (
		<Box onClick={onClick} sx={{cursor:'pointer', ':hover':{opacity:0.6}}}>
			<View sx={s.bank}>
				<Box component="img" sx={s.bankIcon} src={data.bank.image} />
				<View sx={s.bankInfo}>
					<Box sx={s.bankName} >{data.bank.name}</Box>
					<Box sx={s.bankAccountName} >{data.bank_account_name}</Box>
					<Box sx={s.bankAccountNumber} >{data.bank_account_number_thumb}</Box>
				</View>
			</View>
		</Box>
	);
}

export default BankAccountItem;

const s = {
	bank: {
		py:3,
		px:2,
		backgroundColor: 'white',
		borderRadius: 4,
		mt: 2,
		alignItems: 'flex-start',
		gap:2,
	},
	bankName: {
		mt: 1,
		whiteSpace:'nowrap',
		textOverflow:'ellipsis',
		overflow:'hidden',
	},
	bankInfo: {
		flex: 1,
		flexDirection:'column',
	},
	bankAccountName: {
		mt: 4,
		fontSize:13,
		color:'black6',
		whiteSpace:'nowrap',
		textOverflow:'ellipsis',
		overflow:'hidden',
	},
	bankAccountNumber: {
		mt: 1,
		fontSize:26,
	},
	bankIcon: {
		height: 40,
		width: 40,
		borderRadius: 40,
	},
};