import * as React from "react";
import {
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import SystemService from '../services/SystemService';

let GlobaleContext = React.createContext(null);

function Provider(props) {
  let [showTabbar, setShowTabbar] = React.useState(true);
  let [cards, setCards] = React.useState([]);
  let [settings, setSettings] = React.useState({});

  React.useEffect(() => {
    loadSettings();
  }, []);

  const loadSettings = async() => {
    try {
      const response = await SystemService.getSettings();
      setSettings(response);
    } catch(e) {
    }
  }
  
  let inject = { 
    showTabbar, 
    setShowTabbar,
    cards,
    setCards,
    settings,
    loadSettings,
  }
  return <GlobaleContext.Provider value={inject}>{props.children}</GlobaleContext.Provider>;
}

export const useGlobalData = () => {
  return React.useContext(GlobaleContext)
}

export default {
  Provider,
  useGlobalData,
}