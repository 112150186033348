import * as React from "react";
import {
	Box,
	Container,
	IconButton,
	Typography,
} from '@mui/material';
import {
	useNavigate,
} from "react-router-dom";
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';

function HeaderPrimary(props) {
	const {
		label,
		type='light',
		showBackButton=true,
		backTo=null,
	} = props;
	const navigate = useNavigate();

	return (
		<Box
			sx={{
				position:'relative',
				textAlign:'center',
				height:'60px',
				display:'flex',
				lineHeight:'60px',
				justifyContent:'center',
				alignItems:'center',
				pt:3, 
			}}
		>
			{showBackButton && (
				<IconButton
					sx={{
						position:'absolute',
						left:0,
						color:type=='light' ? 'white' : 'black8',
						backgroundColor:'rgba(0,0,0,0.1)',
					}}
					onClick={() => navigate(backTo ? backTo : -1)}
				>
					<KeyboardArrowLeftRoundedIcon sx={{fontSize:34}}/>
				</IconButton>
			)}
			<Typography sx={{color:type=='light' ? 'white' : 'black8'}}>{label}</Typography>
		</Box>
	)
}

export default HeaderPrimary;