import * as React from 'react';
import {
  Box,
  Button,
} from '@mui/material';
import View from '../../components/ui/View';

function EmptyList(props) {

  return (
    <View
      sx={{
        py: 10,
        alignItems: 'center',
        justifyContent: 'center',
        ...props?.sx,
      }}
    >
      <Box sx={{width:200, height:200}} src="/image/empty.svg" component="img" />
    </View>
  );
}

export default EmptyList;