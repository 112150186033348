import * as React from "react";
import {
	Box,
	Container,
	Typography,
} from '@mui/material';
import {
	useNavigate,
	useSearchParams,
	useLocation,
} from "react-router-dom";
import { useAuth } from '../../providers/AuthProvider';
import { useMessage } from '../../providers/MessageProvider';
import {
	getCurrencySymbolByCurrency,
	currencyFormat,
} from '../../services/UtilService';
import View from '../../components/ui/View';
import HeaderPrimary from '../../components/layout/HeaderPrimary';
import GradientButton from '../../components/ui/GradientButton';
import WhiteBox from '../../components/layout/WhiteBox';
import WithdrawService from '../../services/WithdrawService';
import UserService from '../../services/UserService';
import BankService from '../../services/BankService';
import Keyboard from '../../components/trade/Keyboard';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { useGlobalData } from '../../providers/GlobalProvider';
import LoadingModel from '../../components/ui/LoadingModal';
import _ from 'lodash';

function WithdrawCreatePage(props) {
	const { user: currentUser } = useAuth();
	const [userBank, setUserBank] = React.useState();
	const [loading, setLoading] = React.useState(false);
	const [amount, setAmount] = React.useState(0);
	const [amountBalance, setAmountBalance] = React.useState(0);
	const [submiting, setSubmiting] = React.useState(false);
	const [minWithdrawalAmount, setMinWithdrawalAmount] = React.useState(1000);
	const [rewardConfig, setRewardConfig] = React.useState();
	const message = useMessage();
	const location = useLocation();
	const navigate = useNavigate();
	const { setShowTabbar, settings } = useGlobalData();

	React.useEffect(() => {
		reloadWallet();
		getWithdrawalConfig();
		setShowTabbar(false);
		if (location.state?.selectedBank) {
			setUserBank(location.state?.selectedBank);
		} else {
			getLastBank();
		}
		return () => {
			setShowTabbar(true);
		}
	}, [])

	React.useEffect(() => {
		if (location.state?.selectedBank) {
			setUserBank(location.state?.selectedBank);
		}
	}, [location]);

	const reloadWallet = async() => {
		try {
			setLoading(true)
			const response = await UserService.getWallet();
			setAmountBalance(response.amount_balance)
		} catch(e) {
		}
		setLoading(false)
	}

	const getLastBank = async() => {
		const response = await WithdrawService.getLastUsedBank();
		if (response.user_bank) {
			setUserBank(response.user_bank)
		}
	}

	const getWithdrawalConfig = React.useCallback(() => {
		if (!_.isEmpty(settings)) {
			setMinWithdrawalAmount(parseInt(_.find(settings.WITHDRAWAL, i => i.name=='MIN').value));
			setRewardConfig(settings.WITHDRAWAL_REWARD[0]);	
		}
	}, [settings])

	const submitWithdraw = async() => {
		try {
			setSubmiting(true);
			const transaction = await WithdrawService.createWithdraw(userBank.id, amount)
			navigate('/success', {
				state: {
					type: 'withdraw',
					transaction,	
				},
				replace: true,
			});
		} catch(e) {
			message.show(e?.message);
		}
		setSubmiting(false);
	}

	const amountInput = (value) => {
		setAmount(value)
	}

	return (
		<>
			<Container>
				<HeaderPrimary label="Withdraw" type="dark" backTo="/" />
				<View sx={{flex: 1, flexDirection:'column'}}>
					<Box onClick={() => navigate('/bank-accounts')}>
						{userBank && (
							<View sx={s.bankSelection}>
								<Box component="img" src={userBank.bank.image} sx={s.bankSelection.icon}  />
								<Box sx={s.bankSelection.bankInfo}>
									<Box sx={{fontWeight:'bold'}}>{userBank.bank.name}</Box>
									<Box sx={{marginTop: 1,}}>{userBank.bank_account_number_thumb}</Box>
								</Box>
								<ChevronRightRoundedIcon sx={s.bankSelection.moreIcon} />
							</View>
						)}
						{!userBank && (
							<View sx={s.bankSelection}>
								<Box sx={s.bankSelection.bankInfo}>Select a bank</Box>
								<ChevronRightRoundedIcon sx={s.bankSelection.moreIcon} />
							</View>
						)}
					</Box>

					<View sx={s.amountSection}>
						<Box sx={{color: 'black7'}}>₦</Box>
						<Box sx={{color: 'primary.main', marginLeft: 1,}}>
							{currencyFormat(amount)}
						</Box>
					</View>
					<Box sx={s.balanceSection}>
						You have <Box component='b'>{getCurrencySymbolByCurrency(currentUser.currency)}{currencyFormat(amountBalance)}</Box> Available
					</Box>
					{rewardConfig && (
						<Box sx={s.rewardSection}>
							Get <b>{rewardConfig.value}</b> bonus when withdraw over {currencyFormat(rewardConfig.name)}
						</Box>
					)}
					<Box sx={s.tipsSection}>
						Minimum amount {currencyFormat(minWithdrawalAmount)}
					</Box>

					<Keyboard
						onChange={amountInput}
						max={amountBalance}
						min={0}
					/>
				</View>
				<Box sx={s.submitLayer}>
					<GradientButton 
						loading={submiting}
						endIcon={<ChevronRightRoundedIcon sx={{fontSize:'40px !important',}}/>}
						onClick={submitWithdraw}
						disabled={!userBank || amount<minWithdrawalAmount}
						sx={s.submitButton}
					>
						Withdraw
					</GradientButton>
				</Box>
				<LoadingModel open={loading} />
			</Container>
		</>
	);
}

export default WithdrawCreatePage

const s = {
	amountSection: {
		justifyContent: 'center',
		marginBottom: 2,
		marginTop: 8,
		fontSize:40,
		fontWeight:'bold',
	},
	bankSelection: {
		backgroundColor: 'white',
		alignItems: 'center',
		borderRadius: 5,
		marginTop: 3,
		padding: 3,
		cursor:'pointer',
		":hover": {
			opacity:0.6,
		},
		icon: {
			marginRight: 3,
			marginLeft: 1,
			width: 50,
			height: 50,
			borderRadius: 50,
		},
		bankInfo: {
			flex: 1,
		},
		moreIcon: {
			fontSize:40,
			color:'black7',
		}
	},
	balanceSection: {
		textAlign: 'center',
		marginBottom: 1,
	},
	submitLayer: {
		backgroundColor:'white',
		position:'fixed',
		bottom:0,
		left:0,
		width:'100%',
		zIndex:2,
		height: 70,
	},
	submitButton: {
		borderRadius: 0,
	    fontSize: 20,
	    width:'100%',
	    height:70,
	},
	tipsSection: {
		fontSize:12,
		color:'black4',
		textAlign:'center',
		marginBottom: 5,
	},
	rewardSection: {
		fontSize:12,
		color:'warning.main',
		textAlign:'center',
	},
};