import * as React from "react";
import {
	Box,
	Container,
	Typography,
	Button,
	IconButton,
} from '@mui/material';
import {
	useNavigate,
	useSearchParams,
} from "react-router-dom";
import { Player } from '@lottiefiles/react-lottie-player';
import View from '../../components/ui/View';

function SuccessPage(props) {
	const navigate = useNavigate();
	let [searchParams, setSearchParams] = useSearchParams();

	const title = React.useMemo(() => {
		const type = searchParams.get('type')
		if (type=='sell') {
			return 'Successfully Sold';
		} else if(type=='withdraw') {
			return 'Successful Withdrawal';
		} else {
			return 'Successfully';
		}
	}, [searchParams]);

	const toTransactions = () => {
		navigate('/transactions?reload=1');
	}

	return (
		<View sx={{flexDirection:'column', alignItems:'center'}}>
			<Player
				autoplay={true}
				loop={true}
				controls={false}
				src={`/lottie/done.json`}
				style={{ height: '330px' }}
			/>
			<Box
				sx={{
					textAlign: 'center',
					mt:4,
					fontWeight:'bold',
					fontSize:24,
					color: 'success.main',
				}}
			>{title}</Box>
			<Box style={{mt: 12}}>
				<Button
					variant="outlined"
					sx={{mt:4,}}
					onClick={toTransactions}
				>Back To Transactions</Button>
			</Box>
		</View>
	)
}

export default SuccessPage